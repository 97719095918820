import React from "react";
import { motion } from "framer-motion";
// import Flower1 from '../images/flowerTrans.png'; // gives image path

const Rotate = (props) => {
  // const [isActive, setIsActive] = React.useState(false);

  return (
    <motion.div
      style={{
        width: 50,
        height: 50,
        borderRadius: 100,
        backgroundColor: props.color
      }}
      // onClick={() => setIsActive(!isActive)}
      // animate={{
      //   rotate: isActive ? 90 : 0,
      // }}
      whileHover={{ x: 100 }}
      transition={{ ease: "easeOut", duration: 2 }}
      // animate = {{ rotate: 360 }}
    />
  );
};

export default Rotate;
