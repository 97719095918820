// import Rotate from "../components/Rotate";
import React from "react";
import {
  Box,
  // Image,
  // Badge,
  Text,
  Heading,
  // Stack,
  // Button,
  Link,
  Flex,
  Spacer,
  Container,
  Center,
} from '@chakra-ui/react';

// import images
// import Flower1 from "../images/somatic/flowers/redFlower.png";
// import Flower2 from "../images/somatic/flowers/yellowFlower.png";
// import Flower3 from "../images/somatic/flowers/greenFlower.png";
// import Flower4 from "../images/somatic/flowers/orangeFlower.png";
// import Flower5 from "../images/somatic/flowers/swirlFlower.png";
// import Flower6 from "../images/somatic/flowers/cuteFlower.png";
// import Flower7 from "../images/somatic/flowers/turqoiseFlower.png";
// import Flower9 from "../images/somatic/flowers/flower9.png";
// import Flower10 from "../images/somatic/flowers/flower10.png";
// import Flower11 from "../images/somatic/flowers/flower11.png";
// import Flower12 from "../images/somatic/flowers/flower12.png";
import MotionRight from '../components/MotionRight';
import HeaderBox from '../components/headerBox';
import HeaderBoxSmall from '../components/headerBoxSmall'
import useViewport from "../components/useViewport";

const Intro = props => {
  // Hook to toggle dark

  const { width } = useViewport();
  const breakpoint = 620;


  return (
    <div className="concept">
      <Center>
        {' '}
        <Heading
          as="h1"
          size="2xl"
          lineHeight="1.4"
          textAlign="center"
          fontWeight="normal"
        >
          {' '}
          The <span style={{ color: 'red' }}>body</span>LITERATE helps
          neurodiverse learners develop executive function skills. We do this
          through two lenses: executive functions skills training and
          mindfulness and somatic practices.
        </Heading>
      </Center>
      <Flex>
        <Box p="4"></Box>
        <Spacer />
        {/* <Box p="4">
          <MotionRight color={"#FFE57F"} />{" "}
        </Box>
        <Box p="4">
        <MotionRight color={"#32de84"} />
        </Box>
        <Box p="4">
        <MotionRight color={"#7FFF00"} />
        </Box> */}
        <Box p="4"></Box>
        <Box p="4"></Box>
      </Flex>
      <Flex>
        <Box p="4"></Box>
        <Spacer />
        <Box p="4"></Box>
        <Box p="4"></Box>
      </Flex>

{/* FIX THIS */}
{width < breakpoint ? <HeaderBoxSmall /> : <HeaderBox />}
      {/* <HeaderBox /> */}

      <Flex>
        <Box p="4"></Box>
        <Box p="4"></Box>
        <Box p="4"></Box>
        <Box p="4"></Box>
        <Box p="4"></Box>
        <Box p="4"></Box>
        <Box p="4"></Box>
        {/* <Spacer /> */}
        <Box p="4"> {/* <MotionRight color={"#007fff"} />{" "} */}</Box>
        <Box p="4">{/* <MotionRight color={"magenta"} /> */}</Box>
        <Spacer />
        <Box p="4"></Box>
      </Flex>
      <br />
      <Container maxW="container.md">
        <Heading as="h2" size="lg">
          Two questions guide our work{' '}
        </Heading>
      </Container>
      <Container maxW="container.sm">
        <Text fontSize="2xl"></Text>
        <br />
        <Text fontSize="2xl">
          <ul>
            <li>
              {/* <span style={{ color: "magenta" }}> */}{' '}
              <b>
                {' '}
                lens 1: How do we help learners approach external challenges?{' '}
              </b>{' '}
              {/* </span>{" "} */}
              We work with learners to develop better work habits. We help them
              build executive function skills through one-on-one support.
            </li>
            <br />
            <li>
              {/* <span style={{ color: "#007fff" }}> */}
              <b>
                lens 2: How do we help learners develop internal skills to
                manage emotions and focus better?{' '}
              </b>
              {/* </span>{" "} */}
              We work with learners to develop a toolkit of mindfulness skills.
              Students become more aware of their focus and attention. They
              learn how to better navigate stress and difficult emotions.
            </li>
            <br />
            <li>
              <b>putting it together:</b> our one-on-one executive function
              coaching combines both lenses. Students learn life-long executive
              function skills to manage work and routines. They learn how to
              navigate stress and anxiety. We take a developmental approach,
              tailoring both lenses to support joyful, holistic learning.
            </li>
          </ul>
        </Text>
      </Container>
      <br />
      <Flex>
        <Box p="4"></Box>
        <Spacer />
        <Box p="4">
          {' '}
          <MotionRight color={'cyan'} />{' '}
        </Box>
        <Box p="4"></Box>
        <Spacer />
        <Box p="4"></Box>
      </Flex>
      <br />

      <Container maxW="container.md">
        <Text fontSize="2xl">
          <b>
            <span style={{ color: 'red' }}>body</span>LITERATE
          </b>{' '}
          is a <Link href="https://www.thebodyliterate.com/our_team" color="blue" > team of learning and movement specialists.</Link> We combine one-on-one
          executive functions coaching with mindfulness and somatic modalities. We work alongside families and schools
          to support neurodiverse learners. Founder and director, Kai Kleinbard,
          has over a decade of experience helping young learners thrive.
        </Text>
      </Container>
      <br />
      <br />
      <Flex>
        <Box p="4"></Box>
        <Box p="4"> </Box>
        <Box p="4">
          {' '}
          <MotionRight color={'blue'} />{' '}
        </Box>
        <Box p="4"></Box>
        <Box p="4">
          {' '}
          <MotionRight color={'magenta'} />{' '}
        </Box>
        <Spacer />
        <Box p="4"></Box>
        <Box p="4">
          {' '}
          <MotionRight color={'#F2003C'} />{' '}
        </Box>
      </Flex>
    </div>
  );
};

export default Intro;
