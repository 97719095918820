
import React from "react";
import { 
    Box,
    Flex,
    Image, 
    HStack,
    Text, 
    } from '@chakra-ui/react';

function ImageText(props) {
  return (
    <HStack spacing={8}>
      <Box size="200px">
        
      </Box>
        <Flex>
        <Text flexWrap={3} fontSize="2xl" >
        <Image src={props.src} float='left' marginTop='12px' marginRight='20px'/>
        <b>{props.name}</b> {props.text}</Text>
        </Flex>
    </HStack>
  );
}

export default ImageText