import React from "react";
import { motion } from "framer-motion";

export default function CC_03_Duration_based_spring(props) {
  return (
    <div>
      <motion.div
        style={{
          width: 50,
          height: 50,
          // borderRadius: 30,
          backgroundColor: props.color
        }}
        whileHover={{ rotate: 360 }}
        transition={{ type: "spring", duration: 5, bounce: 0.6 }}
      />
    </div>
  );
}
