import React, {useState, useEffect } from "react";
import Header from "./components/header";
import { ChakraProvider, theme } from "@chakra-ui/react";
import ConceptTabs from "./components/ConceptTabs";
import ConceptTabsHoriz from "./components/ConceptTabsHoriz";
import useViewport from "./components/useViewport";

// Google Analytics
// import ReactGA from 'react-ga';
// import RouteChangeTracker from './components/RouteChangeTracker'

function App() {
  const [pageID, setPageID] = useState(0)
  const { width } = useViewport();
  const breakpoint = 620;



  let handleChange = (id) => {
    console.log("app Page", id)
    setPageID(id);
    return id;
  }


  return (
    <ChakraProvider theme={theme}>
      <Header currPage={pageID} />
      {width < breakpoint ? <ConceptTabsHoriz handleChangeProps={handleChange}/> : <ConceptTabs handleChangeProps={handleChange} />}
    </ChakraProvider>
  );
}

export default App;
