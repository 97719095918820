import { ColorModeScript } from "@chakra-ui/react";
import React, { StrictMode } from "react";
import ReactDOM from "react-dom";
import App from "./App";
// import Ef from "./pages/executive_functions_coaching";
import Ourteam from "./pages/our_team";
import SettingUp from "./pages/setting_up_coaching";
import Testimonial from "./pages/Testimonials";
import ATS from "./pages/Articles/AT_scoliosis";
import SPD from "./pages/Articles/AT_SPD"
import ATM from "./pages/Articles/AT_mindfulness"


import Test from "./pages/tester";

import reportWebVitals from "./reportWebVitals";
import * as serviceWorker from "./serviceWorker";
import { Route, Routes, BrowserRouter as Router } from "react-router-dom";


ReactDOM.render(

  
  <StrictMode>
    {/* <head>
      <title>bodyLITERATE | executive functions coaching and mindfulness and somatic practices in Brooklyn, NYC</title>
  </head> */}
    <ColorModeScript />

    <Router>
      <Routes>
        <Route exact path="/" element={<App />} />
        <Route path="/executive_functions_coaching_Brooklyn_NYC/" element={<App />} />
        <Route path="/our_team/" element={<Ourteam />} />
        <Route path="/setting_up_coaching" element={<SettingUp />} />
        <Route path="/mindfulness_and_somatics_for_kids_teenagers_adults_Brooklyn_NYC/" element={<App />} />
        <Route path="/contact/" element={<SettingUp/> } />
        <Route path="/learningpods/" element={<SettingUp/> } />
        <Route path="/manhattan-brooklyn-executive-functioning-adhd-coachingconsultations/" element={<App/> } />
        <Route path="/bio/" element={<Ourteam/> } /> 
        <Route path="/bio/testimonials/" element={<Testimonial/> } />
        <Route path="/bio/faq/" element={<App/> } />
        <Route path="/testimonials/" element={<Testimonial/> } />  
        <Route path="/Alexander_Technique_and_scoliosis_for_kids_teens/" element={<ATS/> } />  
        <Route path="/Alexander_Technique_and_sensory_processing_disorder_for_kids_teens/" element={<SPD/> } /> 
        <Route path="/Alexander_Technique_and_mindfulness_for_kids_teens/" element={<ATM/> } />  
        <Route path="/test/" element={<Test/> } />  
      </Routes>
    </Router>
  </StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
