import React from 'react';
import {
  Tab,
  Tabs,
  // Box,
  TabList,
  TabPanel,
  TabPanels,
  // useColorModeValue,
  useColorMode,
} from '@chakra-ui/react';
import Somatic from '../pages/somatic';
import Ef from '../pages/executive_functions_coaching';
import Intro from '../pages/home';
// import "../pages/pages.css";
import { Link } from 'react-router-dom';
import ContactButton from './ContactUs';

function ConceptTabs(props) {
  const [tabIndex, setTabIndex] = React.useState(0);
  const { colorMode, toggleColorMode } = useColorMode();

  function tabURL(index) {
    const currentURL = window.location.pathname;
    console.log('set tab index to', currentURL);
    if (currentURL === '/executive_functions_coaching_Brooklyn_NYC/') {
      setTabIndex(1);
      console.log('set tab index to 1', currentURL);
      return 1;
    }
    if (
      currentURL ===
      '/mindfulness_and_somatics_for_kids_teenagers_adults_Brooklyn_NYC/'
    ) {
      setTabIndex(2);
      console.log('set tab index to 2', currentURL);
      return 2;
    }
    return 0;
  }

  return (
    <>
      <Tabs
        defaultIndex={tabURL}
        size="lg"
        // borderColor='blue'
        isFitted
        variant="soft-rounded"
        colorScheme="black"
        // defaultIndex={0}
        onChange={index => {
          tabURL(index);
        }}
        // bg={bg}
        // orientation="horizontal"
        // orientation={width < breakpoint ? "vertical" : "horiontal"}
        // display={width < breakpoint ? 'default':"grid"}
        // gridTemplateColumns={width < breakpoint ? 'default' :"auto 1fr"}
      >
        <TabList>
          <Tab
            as={Link}
            to="/"
            _selected={{ borderColor: 'blue', bg: 'skyblue' }}
            _hover={{ backgroundColor: 'lightblue' }}
            fontSize="2xl"
            color={colorMode === 'light' ? 'black' : 'white'}
            fontWeight="normal"
            onClick={()=>props.handleChangeProps(0)}
          >
            <span>
              {' '}
              <b>
                <span>
                  <span style={{ color: 'red' }}>body</span>LITERATE&nbsp;
                </span>
              </b>
              introduction{' '}
            </span>
          </Tab>
          <Tab
            _selected={{ borderColor: 'blue', bg: 'skyblue' }}
            _hover={{ backgroundColor: 'lightblue' }}
            fontSize="2xl"
            color={colorMode === 'light' ? 'black' : 'white'}
            onClick={()=>props.handleChangeProps(1)}
            fontWeight="normal"
            as={Link}
            to="/executive_functions_coaching_Brooklyn_NYC/"
          >
            executive functions coaching
          </Tab>
          <Tab
            as={Link}
            to="/mindfulness_and_somatics_for_kids_teenagers_adults_Brooklyn_NYC/"
            _selected={{ borderColor: 'blue', bg: 'skyblue' }}
            _hover={{ backgroundColor: 'lightblue' }}
            fontSize="2xl"
            color={colorMode === 'light' ? 'black' : 'white'}
            fontWeight="normal"
            onClick={()=>props.handleChangeProps(2)}
          >
            mindfulness and somatics
          </Tab>
        </TabList>
        <TabPanels p="2rem">
          <TabPanel>
            <Intro />
          </TabPanel>
          <TabPanel>
            <Ef />
          </TabPanel>
          <TabPanel>
            <Somatic />
          </TabPanel>
        </TabPanels>
      </Tabs>
      <ContactButton />
    </>
  );
}

export default ConceptTabs;
