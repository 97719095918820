
  import React from 'react';
  import { ChakraProvider, theme } from '@chakra-ui/react';
  import Header from '../../components/header';
  import Markdown from '../../components/markDownBox'
  import ContactButton from "../../components/ContactUs"
  // import './a.css'

  const ATS = props => {
    // Hook to toggle dark
  const articleM = `
  # Alexander Technique and Scoliosis in kids and teenagers 

  _Alternative methodologies for managing scoliosis_
  <br/>
  ## What is scoliosis?
  Scoliosis is the abnormal curvature of the spine. 3 out of 100 people have some form of scoliosis.  It is usually diagnosed in adolescence and can progress during growth spurts.  The spine naturally curves front to back, however, lateral curvature in the spine, taking the shape of a “C” or “S” defines scoliosis. In serious cases, scoliosis can lead to a shortened trunk height, and eventually breathing and postural issues.
  <br/><br/>
  ## The most common type of scoliosis
  Idiopathic scoliosis, the most common form of scoliosis, has multiple causal factors including  genes, skeletons, biomechanics, nervous system, lifestyle and environmental factors. In addition, it can affect the whole being’s way of living, their perception/physical balance and self-esteem.  Scoliosis can worsen over time due to genetic and environmental factors.   According to a study in 2007, by Hermus, Rign and Oojj, “idiopathic scoliosis is a familial condition that has a multifactorial etiology [13, 25]. Idiopathic scoliosis is a genetic trait modified by environmental factors.”  Children are born with a propensity for developing scoliosis and the likelihood of this happening and/or progressing is exacerbated by factors such as how they care for their spines (posture, diet, exercise, etc).  Studies have also shown, such as this one on ballet dancers, that scoliosis can cause a greater likelihood of fractures. If left untreated, scoliosis may lead to serious health effects such as difficulties with childbirth, pulmonary and cardiac issues as well as lower self esteem.
  <br/>
  Girls are ten times more likely to develop idiopathic scoliosis over boys.  According to a study  girls with idiopathic scoliosis may have an earlier growth spurt than girls without scoliosis.  They may also be taller and leaner than their peers at the time of diagnosis.  This accelerated rate of physical change means that physical coordination may suffer as the youth has to re-learn how to use her body.  
  <br/><br/>
  ## What does Scoliosis look like and feel like?
  Individuals with Scoliosis often appear to lean or slump to one side, particularly in her torso. One shoulder may be higher than the other, and one hip may be more prominent than the other. Many children and teens with scoliosis will not have direct pain from the scoliosis, however, without addressing the scoliosis or learning skills to using their bodies more efficiently, pain can develop.  Scoliosis can lead to difficulty with breathing and back pain.
  ![4 types of spinal curves. There is Thoracio. There is lumbar. There is Thoracolumbar. There is double](https://www.bostonoandp.com/Customer-Content/www/CMS/files/CurvePatternScoliosis.jpg)
  _Image description:_ 
There are four types of spinal curves. There is Thoracio, where the curve is located in the thorasic spine. There is lumbar. This is where the spine curves in the lumbar. There is Thoracolumbar and double. Double is where the spine curves in two different places
<br/>  
<br/>
## The Alexander Technique and teenagers and adolescents with scoliosis
  The Alexander Technique (AT) can be a helpful way for people with back issues to learn effective ways to care for their back and find greater fluidity and relief.  Numerous studies, including this one published in the British Medical Journal, have shown the Alexander Technique to be an effective, long-lasting treatment of back issues. While the technique cannot “cure” a person with scoliosis, AT can create lasting changes in how one understands and uses their body more effectively for sustained health and well-being.
  <br/>
  People with scoliosis have reported how the Alexander Technique has been a helpful way for them to develop a greater awareness, self esteem and an overall empowered sense of their bodies. The Scoliosis Association of the UK lists the technique as a helpful practice for people with scoliosis. Additionally, students with scoliosis have reported how AT has helped them.  A teenage student describes,
  <br>
  > “For me, it has been the most helpful, the most crucial thing I have done for my scoliosis, and for my psyche. I recommend it unreservedly for adults or adolescents with scoliosis. I also recommend it with parents in mind, the parents who’ve schlepped their kid to orthopedist and brace-maker and radiologist, who’ve watched their child have to pathologize and quantify their own body, who feel helpless and saddened by the seeming inexorable and fated progression of the disease.”
  </n>
  
  The Technique offers people a way to empower themselves. This puts the control of their bodies in their own hands, allowing them to better manage the symptoms associated with scoliosis. The student furthers this, explaining,
  > “…the reason I highly recommend giving Alexander a try, and a good 5 or more lessons a try, at that, is because, for those with scoliosis, our bodies have been pathologized and problematized in a terrible cycle of self-alienation. The body is a site of censure, and self-abnegation or repulsion….Alexander allows a direct experience of a pleasurable, integrated, fluid, malleable body. While the orthopedist pronounces a seemingly immutable number of degrees of curvature, and by doing so fixes an immutable distortion that the sufferer identifies with, despairs of, Alexander allows for a forgiving, mutable, resonant and alive bodymap.”
  <br/>
  
  The Alexander Technique is an effective way for people to become self-confident. In conjunction with sound medical advice, the AT is a great resource for long lasting spinal care. The body becomes more fluid and adaptable to the environment. Students learn effective self-care practices.
  <br/>
  An adult with scoliosis who also practices the AT with scoliosis describes that in addition to becoming more physically aligned the technique allows her to avoid “putting additional pressure on already wonky disks and joints.  It has also helped me deal with the psychological effects of pain…learning to ‘let go’ of muscle tension [and] helped me feel more in control of my life again.” Similar to the teenager’s description above, the adult finds that the technique empowers her to wrest control of her body back from being pathologized.  In addition, the AT relieves the pain associated with her tendency to compress her spine into her scoliosis.
  <br>
  
Calen Craz discusses her journey with scoliosis as a 12 year old adolescent and discovering the Alexander Technique as a way to manage her scoliosis. Her article is linked [**here**.](https://artofposture.co.uk/scoliosis-and-the-alexander-technique.html#:~:text=Scoliosis%20is%20not%20something%20that%20goes%20away%20and,by%20her%20understanding%20and%20application%20of%20the%20AT.)

## Evidence of the effect of the Alexander Technique on scoliosis
  While many people describe the positive benefits of the AT on scoliosis, more studies need to be done to scientifically prove the effect of the Alexander Technique on scoliosis. The work of physical therapist and Alexander Technique teacher, Deborah Caplan has shown that the technique can help reduce the lateral curvature of the spine for people with scoliosis.  One student, Anne, had a 4 degree change in her spine after having Alexander Technique lessons for a year.  A fellow Alexander Technique teacher, Joshua Lyons, has reduced his curve 15 degrees through his practice of the Alexander Technique.
  ## Alexander Technique in Brooklyn, New York City for adolescents, teenagers with scoliosis
  In my work with adolescents and teenagers with scoliosis, there is a tendency for them to compress into the exaggerated curves of their scoliosis, exacerbating the pain and muscle tightness already present in their backs. Through the Alexander Technique, students learn to identify their center-line and find more support up from their feet.  By learning how to actively support the body in motion, the student stops compressing her spine and finds relief as well as greater agency in managing her scoliosis. In essence, there is the scoliosis (the structure of their spine) and their movement patterns, that are exacerbated by their scoliosis. Helping the young learner become aware of when she’s compressing/tensing her spine, enables her to protect the joints of her body.
  <br/>
  Beyond the psychological benefits, the technique can also have profound effects on a student’s self image. As the student learns to lengthen her spine and become more balanced on her feet, she appears more grounded and self assured.  Students have reported to me how their confidence increases, as well as their performance in various school activities, such as singing, sports, dance and their stamina during long lectures, tests and nightly homework greatly improves.  Numerous studies, including the work of psychologist Amy Cuddy, describe how better posture increases happiness, confidence and overall success in all walks of life.
  </br></br>
****
  By Alexander Technique teacher and Executive Functions coach, Kai Kleinbard, 2016`


    return (
      <>
      <ChakraProvider theme={theme}>
        <Header />
        <Markdown text={articleM} />
        
        <ContactButton />

      </ChakraProvider>
      </>
    );
  };
  
  export default ATS;
  