import React from "react";
import { Grid, 
        GridItem, 
        Center,
        Box } from "@chakra-ui/react";

function ImageBox() {
  return (
    <div>
        <Box border={2} borderRadius={20} borderColor='skyblue'>
      <Grid
        h="200px"
        templateRows="repeat(4, 1fr)"
        templateColumns="repeat(7, 1fr)"
        gap={2}
      >
        <GridItem rowSpan={1} colSpan={2} border="2px" borderColor="magenta"  borderRadius={20}>
          <Center p={2} fontSize="4vw">
            focusing{" "}
          </Center>{" "}
        </GridItem>
        <GridItem rowSpan={1} colSpan={5} />

        <GridItem rowSpan={1} colSpan={1} />
        <GridItem rowSpan={1} colSpan={2} border="2px" borderColor="magenta" borderRadius={20}>
          {" "}
          <Center p={2} fontSize="4vw">
            {" "}
            awareness{" "}
          </Center>{" "}
        </GridItem>
        <GridItem rowSpan={1} colSpan={4} />

        <GridItem rowSpan={1} colSpan={2} />
        <GridItem rowSpan={1} colSpan={2} border="2px" borderColor="magenta" borderRadius={20}>
          {" "}
          <Center p={2} fontSize="4vw">
            {" "}
            emotions{" "}
          </Center>{" "}
        </GridItem>
        <GridItem rowSpan={1} colSpan={3} />

        <GridItem rowSpan={1} colSpan={3} />
        <GridItem rowSpan={1} colSpan={4} border="2px" borderColor="magenta" borderRadius={20}>
          {" "}
          <Center p={2} fontSize="4vw">
            {" "}
            <b> mindfulness and somatics </b>{" "}
          </Center>{" "}
        </GridItem>
      </Grid>
      </Box>
    </div>
  );
}

export default ImageBox;
