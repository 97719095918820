import React, { useState, useEffect } from "react";
import ReactPlayer from "./ReactPlayerEx";
import Drawer from "./drawer";
import { Button, HStack } from "@chakra-ui/react";
import { ColorModeSwitcher } from "../ColorModeSwitcher";
// import styles from "./header.module.css";
import { MdHearing } from "react-icons/md";

function Header(props) {

// for audio
const [showAudio, setAudio] = useState(0);

  // this tells component each time props.currPage changes if showAudio = true, setAudio to false
  useEffect(() => {
    if (showAudio) setAudio(false);
  }, [props.currPage])
  
  // function changeBackgroundColor(e) {
  //   e.target.style.background = 'yellow';
  // }

  // function changeBackgroundColorBack(e) {
  //   e.target.style.background = '#d3d3d3';
  // }

  // need to use an if statement to CHANGE the classname of Drawer to top men and then add a top menu, depending on size
  return (
    <>
      {/* <div className={styles.drawer}> */}
       {/* <HStack whiteSpace= 'nowrap'> */}
       <div paddingLeft="5px">
        <HStack paddingLeft="5px" height="80px">
          <Drawer />
          <ColorModeSwitcher justifySelf="flex-end" />
          <div display='inline-block' width="30%" onClick={setAudio}>
        {showAudio ? (
          <ReactPlayer currPage={props.currPage} />
        ) : (
          <Button padding="10px"  borderRadius={10} border={0}> <MdHearing backgroundColor='d3d3d3'/> &nbsp; Listen to this Page</Button>
        )}{" "}
      </div>
        </HStack>
        </div>
      {/* </HStack> */}
      {/* </div> */}
    </>
  );
}

export default Header;
