import React from "react";


import { Box,
        Button,
        IconButton,
        VStack,
        Spacer,
        Text,
        Link,
        HStack
    } from "@chakra-ui/react";


function ContactSection() {
  // need to use an if statement to CHANGE the classname of Drawer to top men and then add a top menu, depending on size
  return (
    <>
        {/* <Button color="green" bg='yellow' borderRadius={20}>hello</Button> */}
      
      <HStack>
          <Spacer />
      <VStack align='left'  border='2px' borderRadius={20} borderColor='blue' p={5} >
          
          <Box >
          <Text fontSize='1xl'>
          🎈Contact us by email&nbsp;
              {/* <a href='mailto:hi@thebodyliterate.com'> */}
              <Link href="mailto:hi@thebodyliterate.com" color='blue' isExternal>
            
              hi@thebodyliterate.com
             
          </Link>
          {/* </a> */}
          </Text>
          </Box>

          <Box  >
          <Text  fontSize='1xl'>
              or phone&nbsp;
              <Link href="tel:917-693-9960" color='blue' isExternal>
            
              (917) 693 - 9960 
          </Link>
  
          </Text>
          </Box>
        
      </VStack>
      <Box w='30px' />
      </HStack>
    </>
  );
}

export default ContactSection;
