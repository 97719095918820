import React from "react";
// import {
//   Tab,
//   Tabs,
//   // Box,
//   TabList,
//   TabPanel,
//   TabPanels,
//   useColorModeValue
// } from "@chakra-ui/react";
import ContactButton from "../components/ContactUs"


function ConceptTabs() {
 
  return (
    <>
      <ContactButton />
    </>
  );
}

export default ConceptTabs;
