// import Rotate from "../components/Rotate";
import React from 'react';
import {
  Box,
  // Badge,
  Text,
  Flex,
  Heading,
  Stack,
  Link,
  Image,
  VStack,
  Spacer,
  Container,
  Center,
} from '@chakra-ui/react';
// import Header from "./components/header";
import { ChakraProvider, theme } from '@chakra-ui/react';
// import images
// import So_mi_header from "../images/Ef/executivefunction_elements.png";
// import Flower1 from "../images/somatic/flowers/redFlower.png";
// import Flower2 from "../images/somatic/flowers/yellowFlower.png";
import Flower1 from '../images/somatic/flowers/greenFlower.png';
import Flower2 from '../images/somatic/flowers/orangeFlower.png';
import Flower4 from '../images/somatic/flowers/cuteFlower.png';

// import Flower7 from "../images/somatic/flowers/turqoiseFlower.png";
// import Flower9 from "../images/somatic/flowers/flower9.png";
// import Flower10 from "../images/somatic/flowers/flower10.png";
// import Flower11 from "../images/somatic/flowers/flower11.png";
// import Flower12 from "../images/somatic/flowers/flower12.png";
// import Spring from "../components/HoverSpring";
// import MagImage from "../components/MagnifyImage";
import Header from '../components/header';
import Rotate from '../components/Rotate';

// import Christina from "../images/team/Christina2.jpg"
// import Bkmap from "../images/setup/mpb.jpg"
// import ImageText from "../components/ImageText"
// import Kai from "../images/team/Kai.png"
// import Christina from "../images/team/Christina2.jpg"
// import Chuka from "../images/team/Chuka.jpg"
// import DrSusan from "../images/team/DrSusan.png"
// import Austin from "../images/team/Austin.png"
const Team = props => {
  // Hook to toggle dark

  return (
    <ChakraProvider theme={theme}>
      <Header />
      <Center>
        {' '}
        <Heading as="h1" size="2xl">
          {' '}
          Setting up Coaching
        </Heading>
      </Center>
      <br />
      <br />

      <Container maxW="container.lg" lineHeight={1.5}>
        <Heading as="h2" size="lg">
          We listen with giant elephant ears 🐘
        </Heading>
        <Text fontSize="2xl">
          Give us a call or send us an email and we will get back to you within
          24 hours. We offer a free 30-minute consultation by phone. Once we
          talk, we will set up an initial session with the student. The initial meeting is $200. The goal for
          this session is to introduce ourselves to the student. We'd like to
          learn if our approach is right for them and their family. We also use
          this first meeting to match the student up with the right learning
          specialists. After this, we will work with the family (or individual)
          to schedule weekly one-on-one meetings.
        </Text>
        <br />
        <Text fontSize="2xl">
          We offer two types of regular we meetings. Meetings can be in-person, remote or
          hybrid.
        </Text>
        <br />
        <br />
        <br />
        <Heading as="h2" size="lg">
          What are our rates?
        </Heading>
        <br />
        <VStack spacing={10}>
          <Box w="80%" border="2px" borderColor="magenta" borderRadius={20}>
            <Heading margin={2} size="lg">
              Executive Functions Coaching
            </Heading>
            <br />
            <Text margin={2} fontSize="2xl">
              Weekly one-on-one meetings with a learning specialist to develop
              executive functions skills. This is integrated with mindfulness
              and somatics.
            </Text>
            <br />
            <Text fontSize="2xl" margin={2}>
              <mark>$160 per meeting remotely</mark>
            </Text>
            <br />
            <Text fontSize="2xl" margin={2}>
              <mark>$190 per meeting in person</mark>
            </Text>
          </Box>

          <Box w="80%" border="2px" borderColor="blue" borderRadius={20}>
            <Heading as="h2" margin={2} size="lg">
              Mindfulness and Somatic lessons
            </Heading>
            <br />
            <Text margin={2} fontSize="2xl">
              Weekly one-on-one meetings with an Alexander Technique teacher, or
              other somatic specialist. Students develop mindfulness and
              embodied learning skills.
            </Text>
            <br />
            <Text fontSize="2xl" margin={2}>
              <mark>$120 per meeting</mark>
            </Text>
          </Box>
        </VStack>
        <br />
        <br />
        <br />
        <Box>
          <Heading as="h2" size="lg">
            Where do meetings take place?
          </Heading>
          <Text fontSize="2xl">
            <ul>
              <li>
                <Link
                  color="blue"
                  href="https://www.google.com/maps/place/320+Dean+St,+Brooklyn,+NY+11217/@40.6837497,-73.9829816,16.57z/data=!4m5!3m4!1s0x89c25bad1a281b0b:0x2379257775ded27a!8m2!3d40.6837089!4d-73.9809861"
                  isExternal
                >
                  Boerum Hill/Prospect Heights: 320 Dean St
                </Link>
              </li>
              <li>
                <Link
                  color="blue"
                  href="https://goo.gl/maps/XcJydb75rgfdt68aA"
                  isExternal
                >
                  South Slope/Windsor Terrace: 1923 8th Avenue
                </Link>
              </li>
              <li>Remotely via Zoom, Meets, Facetime</li>
            </ul>
          </Text>
        </Box>

        <br />
        <Image
          borderRadius={20}
          src="https://i.postimg.cc/XNmF6hym/bk-Map-Or-Zoom-1.png"
          alt="a map of locations of bodyLITERATE"
        />
        <br />
        <br />

        <Text fontSize="2xl">
          Our spaces are contemplative, spacious, and warm
        </Text>
        <br />
        <Stack direction="row">
          <Image
            boxSize="200px"
            objectFit="cover"
            src="https://i.ibb.co/Xxfp88C/320-Dean-St.jpg"
            alt="320 Dean St contemplative space"
          />
          <Image
            boxSize="200px"
            objectFit="cover"
            src="https://i.ibb.co/vXQ8c93/IMG-6123-2.jpg"
            alt="1923 8th Ave and Kai Kleinbard"
          />
          <Image
            boxSize="200px"
            src="https://i.ibb.co/qrvH0dB/19238ave.jpg"
            alt="1923 8th Ave"
          />
        </Stack>

        <br />
        <br />
        <Box>
          <Heading as="h2" size="lg">
            How do you contact us?
          </Heading>
          <Text fontSize="2xl">
            email us at{' '}
            <Link color="blue" href="mailto:hi@thebodyliterate.com">
              hi@thebodyliterate.com
            </Link>
          </Text>
          <Text fontSize="2xl">call us at 917 693 9960</Text>
        </Box>

        <br />
        <Flex>
          <Box p="4">
            <Rotate src={Flower1} />
          </Box>
          <Box p="4"></Box>
          <Spacer />
          <Box p="4">
            <Rotate src={Flower2} />
          </Box>
          <Box p="4"></Box>
          <Box p="4">
            <Rotate src={Flower4} />
          </Box>
        </Flex>
      </Container>
    </ChakraProvider>
  );
};

export default Team;
