import React from "react";
import { motion } from "framer-motion";
// import Flower1 from '../images/flowerTrans.png'; // gives image path

const Magnify = (props) => {
  // const [isActive, setIsActive] = React.useState(false);

  return (
    <motion.div
    // onClick={() => setIsActive(!isActive)}
    // animate={{
    //   rotate: isActive ? 90 : 0,
    // }}
    // whileHover={{ scale: 1.3 }}
    // animate = {{ rotate: 360 }}
    >
      <div style={{ backgroundColor: props.bg_color, borderRadius: "30px" }}>
        <img src={props.src} alt="this is a heading" />
      </div>
    </motion.div>
  );
};

export default Magnify;
