import React from "react";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  VStack,
  Box
} from "@chakra-ui/react";
import Somatic from "../pages/somatic";
import Ef from "../pages/executive_functions_coaching";
import Intro from "../pages/home";
import "../pages/pages.css";
import ContactButton from "./ContactUs"

function ConceptTabsHoriz(props) {
  // const colors = useColorModeValue(
  //   ["red.50", "teal.50", "blue.50"],
  //   ["red.900", "teal.900", "blue.900"]
  // );
  // const [tabIndex, setTabIndex] = React.useState(0);
  // const bg = colors[tabIndex];

  return (
    <>
      <Accordion allowToggle>
        <AccordionItem>
          <h2>
            <AccordionButton _expanded={{ bg: "#E6FFFA", color: "black" }}>
              <Box flex="1" textAlign="left" onClick={()=>props.handleChangeProps(0)}>
                <span>
                  <b>
                    {" "}
                    <span style={{ color: "red" }}>body</span>LITERATE
                  </b>
                </span>{" "}
                introduction{" "}
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            <Intro />
          </AccordionPanel>
        </AccordionItem>

        <AccordionItem>
          <h2>
            <AccordionButton _expanded={{ bg: "#E6FFFA", color: "black" }}>
              <Box flex="1" textAlign="left" onClick={()=>props.handleChangeProps(1)}>
                executive functions
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            <Ef />
          </AccordionPanel>
        </AccordionItem>

        <AccordionItem>
          <h2>
            <AccordionButton _expanded={{ bg: "#E6FFFA", color: "black" }}>
              <Box flex="1" textAlign="left" onClick={()=>props.handleChangeProps(2)}>
                mindfulness and somatics
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            <Somatic />
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
      <br/>
   
      <VStack align='bottom'>
      <Box h='100px' />
      <br />
      <ContactButton />
      </VStack>
    </>
  );
}

export default ConceptTabsHoriz;
