// import Rotate from "../components/Rotate";
import {
  Box,

  Grid,
  GridItem,

  VStack,

  Center,
} from '@chakra-ui/react';
import React from 'react';


const Team = props => {
  // Hook to toggle dark

  return (
    <>
      <VStack p={5}>
      
      <Box
          w="80%"
          h="205px"
          border="4px"
          borderColor="skyblue"
          borderRadius={20}
        //   marginLeft={100}
        // postion='absolute'
        // right='0px'
        >
          <Grid
            margin="10px"
            h="90%"
            templateRows="repeat(2, 1fr)"
            templateColumns="repeat(3, 1fr)"
            gap={4}
            borderRadius={20}
          >
            <GridItem
              rowSpan={1}
              colSpan={1}
              border="2px"
              borderColor="blue"
              borderRadius={20}
            >
              <Center h="100%" w="100%" fontSize="1xl">
                {' '}
                organization{' '}
              </Center>
            </GridItem>
            <GridItem
              colSpan={2}
              border="2px"
              borderColor="blue"
              borderRadius={20}
            >
              <Center h="100%" w="100%" fontSize="2xl">
                {' '}
                executive functions{' '}
              </Center>
            </GridItem>

            <GridItem
              colSpan={1}
              border="2px"
              borderColor="blue"
              borderRadius={20}
            >
              <Center h="100%" w="100%" fontSize="1xl">
                {' '}
                systems{' '}
              </Center>
            </GridItem>

            <GridItem
              colSpan={1}
              border="2px"
              borderColor="blue"
              borderRadius={20}
            >
              <Center h="100%" w="100%" fontSize="1xl">
                {' '}
                planning{' '}
              </Center>
            </GridItem>

            <GridItem
              colSpan={1}
              border="2px"
              borderColor="blue"
              borderRadius={20}
            >
              <Center h="100%" w="100%" fontSize="1xl">
                {' '}
                parent <br/>support{' '}
              </Center>
            </GridItem>
          </Grid>
        </Box>

        <Box
          w="80%"
          h="205px"
          border="4px"
          borderColor="skyblue"
          borderRadius={20}
        //   marginLeft={100}
        // postion='absolute'
        // right='0px'
        >
          <Grid
            margin="10px"
            h="90%"
            templateRows="repeat(2, 1fr)"
            templateColumns="repeat(3, 1fr)"
            gap={4}
            borderRadius={20}
          >
          
            <GridItem
              colSpan={3}
              border="2px"
              borderColor="magenta"
              borderRadius={20}
            >
              <Center h="100%" w="100%" fontSize="2xl">
                {' '}
                mindfulness and somatics{' '}
              </Center>
            </GridItem>

            <GridItem
              colSpan={1}
              border="2px"
              borderColor="magenta"
              borderRadius={20}
            >
              <Center h="100%" w="100%" fontSize="1xl">
                {' '}
                focusing{' '}
              </Center>
            </GridItem>

            <GridItem
              colSpan={1}
              border="2px"
              borderColor="magenta"
              borderRadius={20}
            >
              <Center h="100%" w="100%" fontSize="1xl">
                {' '}
                awareness{' '}
              </Center>
            </GridItem>

            <GridItem
              colSpan={1}
              border="2px"
              borderColor="magenta"
              borderRadius={20}
            >
              <Center h="100%" w="100%" fontSize="1xl">
                {' '}
                emotions{' '}
              </Center>
            </GridItem>
          </Grid>
        </Box>
      </VStack>
    </>
    // <Container maxW="container.lg" lineHeight={1.5}>

    //   <HStack spacing={10} >
    //    <Box w='45%' border='2px' borderColor='blue' borderRadius={20}>
    //      <Heading margin={2} size='lg'>Executive Functions Coaching: 2 meetings per week</Heading>
    //      <br/>
    //      <Text margin={2} fontSize="2xl">
    //      Two 30-min meetings each week. The first lesson focuses on executive function skills training with academic work (or a job) and routine. The second lesson focuses on mindfulness and somatics.
    //      </Text>
    //      <br/>
    //      <Text fontSize="2xl" margin={2}>
    //     175 includes both meetings
    //      </Text>
    //    </Box>

    //    <Box w='45%'  border='2px' borderColor='magenta' borderRadius={20}>
    //      <Heading margin={2} size='lg'>Mindfulness and Somatics</Heading>
    //      <br/>
    //      <Text margin={2} fontSize="2xl">
    //      45 min session executive function skills training around academic work (or a job) and routines. This is integrated with mindfulness and somatics.
    //      </Text>
    //      <br/>
    //      <Text fontSize="2xl" margin={2}>
    //      175 per meeting
    //      </Text>
    //    </Box>

    //     </HStack>
    //   </Container>
  );
};

export default Team;
