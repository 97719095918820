
  import React from 'react';
  import { ChakraProvider, theme } from '@chakra-ui/react';
  import Header from '../../components/header';
  import Markdown from '../../components/markDownBox'
  import ContactButton from "../../components/ContactUs"

  // import './a.css'

  const ATS = props => {
    // Hook to toggle dark
  const articleM = `
  # Brooklyn, NYC Sensory processing disorder and the Alexander Technique for teenagers and adolescents
<br/>
_Alternate treatment/therapy for sensory processing issues for youth_
<br/>
## What is sensory processing disorder?

SPD or sensory processing disorder is a condition in which the brain has difficulty receiving and responding to information that comes in through the senses. Adolescents and teenagers with sensory issues may overreact or be unreactive to various stimuli that compromise normal everyday function. Your child can be evaluated by a doctor, neurologist or occupational therapist to determine if there are sensory issues.
Sensory issues are on a spectrum; most of us have certain quirks that can be a nuisance, yet they don’t compromise our ability to perform.  When sensory issues, such as sensitivity to noise, poor balance/coordination, anxiety around crowds, etc., begin to interfere with everyday functioning such as doing homework, learning in school, social situations, etc., then it may be helpful to seek out extra support, such as an occupational therapist or Alexander Technique teacher to help your child develop skill-sets to manage their processing issues.
## How do you know if your child has sensory issues?

When sensory preferences interfere with normal everyday function, children may be over-responsive or under-responsive to sensory stimuli.  The child may have a hard time focusing; she may seem lethargic in the morning, or extremely picky while eating. Additionally, the student may be uncoordinated, bumping into things and/or have a very collapsed or overly tense posture [linked **here**](http://www.eileenparker.com/2009/03/posture-overload-in-sensory-processing-disorder/) while sitting and standing — [here](https://www.understood.org/en/learning-attention-issues/child-learning-disabilities/sensory-processing-issues/sensory-processing-issues-what-youre-seeing-in-your-high-schooler) is a more thorough list of symptoms of sensory issues.
![There are different type of symptoms for sensory processing disorder](http://www.inha.ie/wp-content/uploads/2015/08/GRAPHIC-I-HAVE-SENSORY-PROCESSING-DISORDER.gif)
  _Image description:_ 
<br/>
There are multiple symptoms of sensory processing disorder. It comes in many forms. A black shadow of a child, probably around 10 years old. Surrounding the child are many symptoms of SPD.
<br/>  

## Incorrect posture may exacerbate the symptoms of sensory processing disorder
Dr. Paul Drew, (his work is [linked **here**](http://www.eileenparker.com/2009/03/posture-overload-in-sensory-processing-disorder/)), a physical therapist from the University of Southern California, points out that when an individual with sensory issues “is standing, moving, or sitting with incorrect posture and not in the correct alignment, he or she may experience an overload of stimuli.” People without a sensory disorder can interpret muscle tension accurately as a collapse or overstaining; on the other hand, people with sensory issues, may misinterpret tension in the body as something else and they may have difficulty finding a coherent response.
<br/>
Occupational therapists, Britt Collins and Lucy Jane-Miller published an article [linked **here**](http://www.spdfoundation.net/files/9214/2430/1256/Jul-Aug2012-SBMD-PD.pdf) for the Sensory Processing Foundation pointing out that  “increasing a child’s postural strength and control increases his capability to move more efficiently in space, walk on uneven surfaces without tripping, balance, sit or stand upright, and more.”  By improving the child’s posture, he obtains more stability in his movement patterns, making it less likely that accidents occur, increasing the child’s self esteem and ability to discern various sensorial experiences.
## How the Alexander Technique can support adolescents and teenagers with sensory issues
The Alexander Technique (AT) helps young learners develop more awareness of how their bodies respond to stimulus. Through the technique, youth learn how to center their bodies, and develop life-long skills for increasing their perception of space, and coordination.  The AT tunes a person’s senses so that she can better identify when subtle changes take place that disrupt balance.  One learns how to more accurately assess their environment, improving her ability to function in daily life.
Alexander Technique teacher, Caitlin Freeman, has sensory processing disorder and describes that also linked  [**here**](http://www.bodylearningblog.com/autism-the-alexander-technique-and-me/)


>“Because of my aversion to touch, I was initially apprehensive about taking Alexander Technique lessons. Despite my fears, my teacher’s hands-on work was soothing, and I gradually learned how to calm my overactive nervous system…Over the course of that year, my Alexander lessons allowed me to begin integrating my senses of touch, movement, and balance. Further, without my constant sensory distress, I became increasingly aware of the social world around me.”
<br/>

The AT helped Caitlin integrate her senses of touch and balance.  She also developed a more accurate map of the world around her, allowing her to become “graceful for the first time in [her] life, after having sprained  [her] ankles eight times as a teenager.”  The AT helped her discover “the conscious choice that [she] had in [her] actions” as she “became aware of the time between stimulus and response.”  The AT enabled Caitlin to develop better balance as she was able to respond more healthfully to the world around her. In addition, she was able to inhibit knee-jerk responses and make better choices.
<br/>
The Alexander Technique interrupts the “processing” step so that students learn to pause before they respond. In this way, they re-train their habitual reactions to stimuli to better support their needs.
## My experience working with students with sensory issues using the Alexander Technique
Personally, the Alexander Technique has helped me develop a stronger sense of my center and balance.  In turn, this has given me the ability to discern when certain sensations are due to my own body use, such as prolonged sitting in front of a computer screen, or craning my neck to view my phone.  This has empowered me to take agency in living more comfortably in my own body.  I can make adjustments to feel comfortable and calm myself down.
<br/>
For my students with sensory processing issues, the Alexander Technique has given them a way to calm themselves, when they are hypersensitive to the stimuli around them.  For example, one student, who was very sensitive to touch, found that when he allowed his body to relax, he felt more in control of how he reacted to touch.  Another student, who often careened down the hall, as if he was a bowling ball, found that when he become more centered (stacking his head over his pelvis) he was able to become much more balanced and calm in his movement patterns. Another example, is a young teenage singer student, who found that by using Alexander Technique principles, she was able to expand her perception of space and this in turn allowed her to soothe her anxiety, as well as to become more resonant in her singing.
<br/>
More scientific research is needed to support how the Alexander Technique can help adolescents and teenagers develop skills to offset their sensory issues.  While the technique will not cure sensory issues, it can enable people to develop tools/skill-sets to manage their challenges.  It is a helpful, holistic treatment for those interested in obtaining more agency in how they respond to sensory stimuli.
<br/>
Below is a list of various stimuli, adapted from [http://www.sensory-processing-disorder.com/](http://www.sensory-processing-disorder.com/), that can be used to alert or calm depending on what’s needed:

### ALERTING STIMULI
- variable music, sounds
- bright, artificial light
- crunch or chew
- salty, spicy, or bitter flavors
- rotary and/or oscillating movement
- cold or hot temperatures
- light touch
- hanging or stretching muscles/joints
- horizontal head position
### CALMING STIMULI
- soft, rhythmic sounds
- dim or natural lighting
- chew or suck
- sweet tastes
- linear movement
- neutral warm temperatures
- deep pressure
- carrying and/or pushing objects
- vertical head position

----

by Kai Kleinbard, 2016, executive functions coach and Alexander Technique teacher

----


For more information on sensory processing disorder visit: [http://www.spdfoundation.net/]( http://www.spdfoundation.net/)

`


    return (
      <>
      <ChakraProvider theme={theme}>
        <Header />
        <Markdown text={articleM} />
            <ContactButton />
      </ChakraProvider>
      </>
    );
  };
  
  export default ATS;
  



