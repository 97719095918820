import React from "react";
import { NavLink } from "react-router-dom";
import {
  Button,
  // ButtonGroup
  Stack
} from '@chakra-ui/react'

function MenuList(props) {
  //   const { isOpen, onOpen, onClose } = useDisclosure()
  //   const btnRef = React.useRef()

  const links = [
    {
      id: 7,
      path: "/",
      text: "What we do"
    },
    {
      id: 3,
      path: "/our_team",
      text: "Meet our Team"
    },
    {
      id: 4,
      path: "/setting_up_coaching",
      text: "Set up Coaching"
    }
  ];

  return (
    <ul className="menulist">
      {links.map((link) => {
        return (
          <div key={link.id}>
            <NavLink
              to={link.path}
              activeClassName="active-link"
              exact
            >
              <Stack spacing={10} direction='column' >
                <Button size='md' colorScheme='teal' variant='outline' margin={5} bg='#ffffe0' border='2px' borderColor='blue' color="blue">
                {link.text}
                </Button>
              </Stack>
              
            </NavLink>
          </div>
        );
      })}
    </ul>
  );
}

export default MenuList;
