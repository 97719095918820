import React from 'react';
import Rotate from '../components/Rotate';
import {
  Box,
  // Badge,
  Text,
  Heading,
  VStack,
  Link,
  Flex,
  Spacer,
  Container,
  Center,
} from '@chakra-ui/react';
// import { ExternalLinkIcon } from '@chakra-ui/icons';
// import images
import So_mi_header from '../images/somatic/so_mi_mind_map2.png';
import Flower1 from '../images/somatic/flowers/redFlower.png';
import Flower2 from '../images/somatic/flowers/yellowFlower.png';
import Flower3 from '../images/somatic/flowers/greenFlower.png';
import Flower4 from '../images/somatic/flowers/orangeFlower.png';
// import Flower5 from "../images/somatic/flowers/swirlFlower.png";
import Flower6 from '../images/somatic/flowers/cuteFlower.png';
import Flower7 from '../images/somatic/flowers/turqoiseFlower.png';
import Flower9 from '../images/somatic/flowers/flower9.png';
import Flower10 from '../images/somatic/flowers/flower10.png';
import Flower11 from '../images/somatic/flowers/flower11.png';
import Flower12 from '../images/somatic/flowers/flower12.png';
import useViewport from '../components/useViewport';
import MagImg from '../components/MagnifyImage';
import ConceptBox from '../components/ConceptImageBox_som';
// import "./pages.css";

const Concept = props => {
  // Hook to toggle dark

  const { width } = useViewport();
  const breakpoint = 620;

  return (
    <div className="concept">
      <Center>
        {' '}
        <Heading as="h1" size="2xl" fontWeight="normal">
          {' '}
          mindfulness and somatics
        </Heading>
      </Center>
      <br />
      <br />
      {width < breakpoint ? (
        <Center>
          <Box>
            {' '}
            <ConceptBox /> <br /> <br />{' '}
          </Box>
        </Center>
      ) : (
        <>
          <VStack>
            <Center>
              <MagImg src={So_mi_header} bg_color="white" />
            </Center>
            <Flex>
              <Box p="4"></Box>
              <Box p="4"></Box>
              <Box p="4"></Box>
              <Box p="4"></Box>
              <Box p="4"></Box>
              <Box p="4"></Box>
              <Box p="4">
                <Rotate src={Flower6} />
              </Box>
              <Spacer />
              <Box p="4"></Box>
            </Flex>{' '}
          </VStack>
        </>
      )}
      <Box h="5px" p={5}></Box>
      <br />
      <Container maxW="container.md">
        <Heading as="h2" size="lg">
          What we offer
        </Heading>
      </Container>
      <Container maxW="container.sm">
        <Text fontSize="2xl">
          <ul>
            <li>
              mindfulness and somatic practices include Alexander Technique,
              meditation and yoga
            </li>
            <li>
              mindfulness and somatics are included in executive function
              coaching
            </li>
            <li>
              tayloring mindfulness and somatics to suit individual and family
              goals
            </li>
          </ul>
        </Text>
      </Container>
      <Flex>
        <Box p="4"></Box>
        <Spacer />
        <Box p="4">
          <Rotate src={Flower1} />
        </Box>
      </Flex>
      <Container maxW="container.md">
        <Heading as="h2" size="lg">
          {' '}
          What do students learn?{' '}
        </Heading>
      </Container>
      <Container maxW="container.sm">
        <Text fontSize="2xl">
          <ul>
            <li>techniques to refocus</li>
            <li>focus and attention involve the whole body</li>
            <li>posture awareness</li>
            <li>de-stress techniques</li>
            <li>breath awareness</li>
          </ul>
        </Text>
      </Container>
      <Flex>
        <Box p="4">
          <Rotate src={Flower2} />
        </Box>
        <Spacer />
        <Box p="4"></Box>
      </Flex>
      <Text></Text>
      <Container maxW="container.md">
        <Heading as="h2" size="lg">
          How do mindfulness and somatics relate to executive functions?
        </Heading>
        <Text fontSize="2xl">
          Somatic and mindfulness practices teach students to become more aware
          of their bodies. They can better track patterns and sensations that
          lead to distraction. They can better direct their focus. They can also
          develop better habits around how they work. This become more efficient
          and objective as they approach their tasks.{' '}
        </Text>
        <br />
        
        <Text fontSize="2xl">
          We adapt our mindfulness lessons to each individual. Students learn
          how to refocus their attention on the present moment. They also learn
          how to apply these principles to their learning. For example, how they
          can recenter themselves during an anxiety-producing test. Or how they
          might refocus to start homework. Or how they might bring awareness to
          their breath during a conflict with a teacher, parent or sibling.
        </Text>{' '}
        <br />
      </Container>
      <Flex>
        <Spacer />
        <Box p="4"></Box>
        <Box p="4">
          <Rotate src={Flower3} />
        </Box>
        <Box p="4">
          <Rotate src={Flower4} />
        </Box>
        <Spacer />
        <Box p="4">
          <Rotate src={Flower6} />
        </Box>
      </Flex>

      <Container maxW="container.md">
        <Heading as="h2" size="lg">
          What is the Alexander Technique?{' '}
        </Heading>
      </Container>

      <Container maxW="container.md">
        <Text fontSize="2xl">
          The Alexander Technique (AT) is a re-education method. It brings
          mindfulness, ease and connectivity to everyday movement. The technique
          improves posture and physical efficiency.
        </Text>
        <br />
        <Text fontSize="2xl">
          This <Link
                href="https://www.youtube.com/watch?v=G9bzdRuwRC0"
                color="blue"
                isExternal
              >
                video{' '}
              </Link>
          describes an example of how AT is used in UK schools.
        </Text>
        <br />
        <Text fontSize="2xl">
          We also have experience working with learners with scoliosis, and
          sensory processing issues. We help them manage their condition. We
          support them to build more confidence in themselves. <br />
        </Text>
      </Container>
      <br />
      <Flex>
        <Box p="4">
          <Rotate src={Flower9} />
        </Box>
        <Spacer />
        <Box p="4">
          <Rotate src={Flower10} />
        </Box>
        <Box p="4">
          <Rotate src={Flower11} />
        </Box>
        <Box p="4">
          <Rotate src={Flower12} />
        </Box>
      </Flex>

      <Container maxW="container.md">
        <Heading as="h2" size="lg">
          Helpful Resources
        </Heading>
      </Container>
      <Container maxW="container.sm">
        <Text fontSize="2xl">
          <ul>
            <li>
              {' '}
              <Link
                href="https://pubmed.ncbi.nlm.nih.gov/30852914/"
                color="blue"
                isExternal
              >
                Study: mindfulness and executive function skills{' '}
              </Link>
            </li>
            <li>
              {' '}
              <Link
                href="https://thebodyliterate.com/Alexander_Technique_and_scoliosis_for_kids_teens/"
                color="blue"
                isExternal
              >
                Alexander Technique and Scoliosis for Kids and Teens{' '}
              </Link>
            </li>
            <li>
              {' '}
              <Link
                href="https://thebodyliterate.com/Alexander_Technique_and_sensory_processing_disorder_for_kids_teens/"
                color="blue"
                isExternal
              >
                Alexander Technique and Sensory Processing Disorder for Kids and
                Teens{' '}
              </Link>
            </li>
            <li>
              {' '}
              <Link
                href="https://thebodyliterate.com/Alexander_Technique_and_mindfulness_for_kids_teens/"
                color="blue"
                isExternal
              >
                Alexander Technique and Mindfulness{' '}
              </Link>
            </li>
          </ul>
        </Text>
      </Container>
      {/* 
          <Link href="https://chakra-ui.com" isExternal>
            <Button variantColor="teal" variant="outline">
              🔗 Study: mindfulness and executive function skills{" "}
            </Button>
          </Link>
          <Link href="https://chakra-ui.com" isExternal>
            <Button variantColor="teal" variant="outline">
              🔗 Study: mindfulness and executive function skills{" "}
            </Button>
          </Link>
          <Link href="https://chakra-ui.com" isExternal>
            <Button variantColor="teal" variant="outline">
              🔗 Study: mindfulness and executive function skills{" "}
            </Button>
          </Link> */}
      {/* </Text> */}
      {/* </Container> */}
      <Flex>
        <Spacer />
        <Box p="4"></Box>

        <Box p="4">
          <Rotate src={Flower7} />
        </Box>
        <Spacer />
        <Box p="4"></Box>
      </Flex>
    </div>
  );
};

export default Concept;
