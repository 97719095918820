import React from "react";
import {
  Grid,
  GridItem,
  Center,
  Box
} from "@chakra-ui/react";

function ImageBox() {
  return (
    <div>
        <Box >
    <Grid
      h="200px"
      templateRows="repeat(4, 1fr)"
      templateColumns="repeat(7, 1fr)"
      gap={2}
    >
      <GridItem rowSpan={1} colSpan={2} border="2px" borderColor="royalblue">
        <Center p={2} fontSize="4vw">
          organization{" "}
        </Center>{" "}
      </GridItem>
      <GridItem rowSpan={1} colSpan={5} />

      <GridItem rowSpan={1} colSpan={1} />
      <GridItem rowSpan={1} colSpan={2} border="2px" borderColor="royalblue">
        {" "}
        <Center p={2} fontSize="4vw">
          {" "}
          self-motivation{" "}
        </Center>{" "}
      </GridItem>
      <GridItem rowSpan={1} colSpan={4} />

      <GridItem rowSpan={1} colSpan={2} />
      <GridItem rowSpan={1} colSpan={2} border="2px" borderColor="royalblue">
        {" "}
        <Center p={2} fontSize="4vw">
          {" "}
          helping parents{" "}
        </Center>{" "}
      </GridItem>
      <GridItem rowSpan={1} colSpan={3} />

      <GridItem rowSpan={1} colSpan={3} />
      <GridItem rowSpan={1} colSpan={4} border="2px" borderColor="royalblue">
        {" "}
        <Center p={2} fontSize="4vw">
          {" "}
          <b> executive functions </b>{" "}
        </Center>{" "}
      </GridItem>
    </Grid>
    </Box>
  </div>
  );
}

export default ImageBox;
