// import Rotate from "../components/Rotate";
import React from "react";
import {
  Box,
  // Badge,
  Text,
  Heading,
  // Stack,
  VStack,
  Container,
  Center,
} from '@chakra-ui/react';
// import Header from "./components/header";
import { ChakraProvider, theme } from '@chakra-ui/react';
// import images
import ContactButton from '../components/ContactUs';

import Header from '../components/header';
import ImageText from '../components/ImageText';
import Kai from '../images/team/Kai.png';
import Christina from '../images/team/Christina2.jpg';
import Chuka from '../images/team/Chuka.jpg';
import DrSusan from '../images/team/DrSusan.png';
import Austin from '../images/team/Austin.png';
import Ellen from '../images/team/Ellen.png';
import April from '../images/team/April.png';
import Dave from '../images/team/Dave.png';
import Kevin from '../images/team/Kevin.png';
import Takehiro from '../images/team/Takehiro.png';
import Simone from '../images/team/Simone.png';
import Elizabeth from '../images/team/Elizabeth.jpg';
import Celeste from '../images/team/Celeste.png';





const Team = props => {
  // Hook to toggle dark

  return (
    <ChakraProvider theme={theme}>
      <Header />
      <Center>
        {' '}
        <Heading as="h1" size="2xl">
          {' '}
          Meet the Team
        </Heading>
      </Center>
      <br />                
      <br />

      <Container maxW="container.lg" lineHeight={1.5}>
        <Text fontSize="2xl">
          bodyLITERATE aims to be a diverse and supportive organization. Our incredible
          team has been brought together with care and compassion. Our learning
          specialists have advanced degrees and years of experience.
        </Text>
        <br />
        <Text fontSize="2xl">
          At the heart of this work is the trusting relationship between the
          student and their specialist. We take great care to match each student
          up with the right learning specialists.
        </Text>
        <br />
        <VStack spacing={10}>
          <ImageText
            src={Kai}
            name="Kai Kleinbard"
            text="is the founder and director of bodyLITERATE. He has a Masters Degree from the University of Pennsylvania, School of Engineering with a focus on education technology. At UPenn Kai designed software to support greater digital accessibility for neuro-diverse learners. Kai grew up in Brooklyn and attended Packer Collegiate Institute and Sarah Lawrence College. Kai has over a decade experience as an executive functions coach and an AmSAT certified Alexander Technique teacher, specializing in how the body can support focus and attention. He has worked extensively with Brooklyn Friends School, Packer Collegiate Institute, PS 154, Brooklyn Friends, Saint Ann’s, Friends Seminary, Poly Prep, PS 321, PS 447 and many more schools. Additionally, he is a trained mediator through Good Shepherd Services, supporting families at conflict with the education system. He has studied with William R Miller, Ph.D., founder of Motivational Interviewing, a therapy modality, through the Columbia School of Social Work. In addition, Kai continues to be an active movement artist/choreographer. He directs the community-based Institute for Playful Beings, a group of educators that utilize play theory to support learning."
          />
          <ImageText
            src={April}
            name="April Frasier"
            text="Prior to being a mindfulness and educational consultant, April was a teacher at a resilient school in the South Bronx. April was part of the inaugural cohort at the Spirituality Mind Body Institute at Columbia University Teachers College where she received her Master’s Degree in Spirituality and Mindfulness. Prior to this, she received a Masters degree in education at Fordham University, and BS International Relations, Community Development and Educational Studies from Mount Holyoke College. She created Ahimsa MY LLC, focusing on bringing mindfulness and yoga to schools and educational organizations as a means to cultivate present teachers and self-empowered students. April hopes that the practices of mindfulness can reconnect teachers and young people back to their inner resources of breath and body to navigate external uncertainties. April has partnered with schools across the country as well as organizations such as Teach for America, City Year, and Citizen Schools. She is a teacher with the Lineage Project, an organization that teaches trauma-informed mindfulness to incarcerated adjacent young people and staff who support them. She also is a teacher with Mindful Schools, an international mindfulness organization supporting in integrating mindfulness into school communities."
          />
          <ImageText
            src={Austin}
            name="Austin Lee"
            text="received a bachelor’s degree in Black Studies from Amherst College and a master’s degree in sociology from the University of Pennsylvania (UPenn). She is a current doctoral candidate in Sociology at UPenn, where she studies race and sexuality. As an instructor and mentor, she motivates students to honor their own ideas and develop perspectives as learners and scholars. Austin has mentored incoming first-year students in adapting their organizational, social, and study habits to enable smoother transitions from high school to college. As an instructor at the University of Pennsylvania, she works with students to cultivate their research interests and execute qualitative and quantitative research projects. Through Youth Volunteers Corps, she has led and empowered youth through service-learning projects and addressing social issues in their communities. Additionally, Austin has mentored, coached and tutored a wide spectrum of learners ages 6 – 18 through Girls Inc. Austin works to impart students with problem-solving skills and work strategies that prepare them for the classroom and beyond."
          />
          <ImageText
            src={Celeste}
            name="Celeste Alexander"
            text=" Celeste Alexander has a lifelong passion for teaching and learning and has taught everything from swimming lessons to college courses. She earned a bachelor’s degree from the University of Chicago and a master’s in anthropology from Princeton University where she also served as a lecturer and research specialist. Celeste currently works in learning support services at the Dalton School. Prior to this, she worked in operations at a Brooklyn-based psychology practice that specializes in therapy and neuropsychological assessments for young people and families. She has served as a lecturer at Princeton University, Rutgers University, and John Jay College of Criminal Justice. Over the course of her career as an educator, she has taught students of all ages and across subjects including literacy, writing, math, STEAM, global health, and anthropology. Prior to pursuing graduate studies, Celeste worked for the Center for Sustainable Urban Development (CSUD) at Columbia University’s Earth Institute where she participated in and helped foster education and research for physically and socially sustainable cities. She is also a published author and worked for several years in production and acquisitions editing at Oxford University Press."
          />
          <ImageText
            src={Christina}
            name="Christina Hoodho"
            text=" is a Nationally Registered and Certified Art Therapist, and New York State Licensed Creative Arts Therapist and Psychotherapist. She earned her Masters degree in Creative Arts Therapy from Hofstra University. For over a decade now, Christina has worked therapeutically with a diverse clientele of all ages, ethnicities, genders, socioeconomic backgrounds etc., including but not limited to inmates, inpatient psychiatric clients, individuals with disabilities, children with autism, the homeless, domestic violence survivors, veterans, the LGBTQ community, children in foster care, adolescents in group homes, immigrants, the elderly, addicts and recovering addicts. She has served in an array of educational settings, such as schools, libraries, museums, inpatient pediatric and adolescent psychiatric units, and Rikers Island. Integrating Executive Functioning skills is a vital part of preparing clients for success in the future. This was particularly evident for her when working with inmates, children residing in group homes and the homeless. In addition to her executive functions coaching practice with bodyLITERATE, she has an art therapy private practice. Christina also enjoys employing her creativity as a teaching artist. She has personally experienced the benefits of eastern medicine, Ayurveda, holistic health and Native American shamanism, and meditation. Christina implements these techniques in her practice."
          />

          <ImageText
            src={Chuka}
            name="Chuka Osakwe"
            text="has dedicated his life to learning and enjoys cultivating an interest in education in others.  He has a bachelor’s degree in engineering from Cornell University, an MBA from London Business School and is currently pursuing  a computer science master’s degree at the University of Pennsylvania. Throughout his pursuit of education, he has coached and taught in many different settings, including the USA, UK, Nigeria, UAE and South Africa. Currently, Chuka works as a product manager where he leads organizational wide strategic initiatives, managing and planning out tasks for a large group of people. In addition to coaching at bodyLITERATE, he is investigating ways to innovate the fintech ecosystem in Africa, whether through technology or education."
          />
          <ImageText
            src={Dave}
            name="Dave Maharaj"
            text="has spent most of his adult life teaching whether in martial arts or academia. He has a
            Masters Degree in Mechanical Engineering from City College of New York, and a Phd in
            Mechanical Engineering from The Ohio State University. He was a Lab Teaching Assistant
            during his tenure as a Masters student, and then a Teaching Adjunct after graduating from City
            College of New York. At OSU he taught Labs in Engineering Design, and Systems Control,
            whilst pursuing his degree. He volunteered for several years with Engineers Without Borders NY
            Chapter, and travelled with his team to Kenya to bring potable water to the Misuuni Village. He
            currently tutors students with and without disabilities. He is focused on helping his students
            change their mindset towards learning and studying, and developing strategies that can benefit
            them, long after they have left his tutelage.
            "
          />
          <ImageText
            src={Ellen}
            name="Ellen Song"
            text="graduated from Williams College with a B.A. in English. She worked as a Fulbright Teaching Assistant at a secondary school in Madrid, Spain. Ellen next received her PhD in English from Duke University. For three years, she was an award-winning Lecturer on History and Literature at Harvard University, where she taught courses in ethnic studies and contemporary American literature. A Queens native, Ellen is currently an Editor in New York City. 
For over a decade of teaching and tutoring, Ellen has worked with high school students, undergraduates, and doctoral candidates. She is an experienced academic coach for international students, students with learning disabilities, and student athletes. Her areas of expertise include the expository essay, literature, history, admissions essays, and time management skills."
          />
          <ImageText
            src={Kevin}
            name="Kevin Rhee"
            text="Kevin has a lifelong passion for education and mentorship, and strives to confer that same fervor onto others. He graduated summa cum laude from Boston College with a B.S. in biochemistry, and later obtained his M.D. degree from Rutgers Robert Wood Johnson Medical School. He currently practices as a resident general surgeon in New Jersey, particularly with the underprivileged populations of Newark. As an academic surgeon, his day to day is brimming with learning, and for over 10 years now, he has mentored and taught students of various backgrounds and stages of life. Kevin cherishes the opportunity to use his experiences in guiding students to achieve all their academic and career goals. He particularly emphasizes mindfulness, critical thinking, and goal-oriented planning as it pertains to the workplace, school, and local communities. His areas of expertise include time management and organization, motivational learning, college and graduate school admission prep, and standardized examination prep. Outside of the hospital and classroom, he enjoys playing sports, cooking, and spending time with his friends, family, and 3 Shih Tzus."
          />
            <ImageText
            src={Simone}
            name="Simone Cooper"
            text="received her bachelor’s degree in Applied Psychology from New York University where she was an athlete on the Track and Field team and her Master’s degree in Education and Youth Development from Boston University where she focused her efforts on sport psychology and performance enhancement. As a former Academic Advisor for collegiate student-athletes, adjunct lecturer, and tutor, Simone understands the diverse needs of students from all ages, abilities and backgrounds and uses creative methods to encourage holistic success. Currently, Simone is a doctoral candidate at Kean University in school and clinical psychology. She utilizes trauma informed, strengths-based and mindfulness based approaches to meet the needs of her students and clients whether in the classroom, therapy room or sports setting."
          />
          <ImageText
            src={DrSusan}
            name="Susan Weseen"
            text="Dr. Weseen (Susan) graduated from Harvard and received her Masters degree in Education from Bank Street, as well as a Ph.D. in Social-Personality Psychology from the Graduate Center at CUNY. During her 15 years working at a public school, Susan implemented key programs that taught mindfulness, emotional literacy, critical thinking and joyful learning, including: a full Library, the school’s garden/farmers market, a Wellness and Sustainability program; the Yoga program, and a mindfulness cluster class. She has taught in CUNY’s Writing Across the Curriculum program at York College, and in the Psychology Department at Hunter College, and has written extensively on how educational and cultural systems impact individuals. In addition to her executive functions coaching practice with bodyLITERATE, Susan, currently works part-time with Teaching Beyond the Square’s Materialized Classroom project."
          />
            <ImageText
            src={Elizabeth}
            name="Elizabeth Heck"
            text="After graduating from law school, Liz worked in publishing in the California Bay Area and then transitioned to teaching, before moving to Costa Rica to teach middle and high school students. For the past thirteen years, Liz has taught a variety of Upper School English classes and electives, including the International Baccalaureate curriculum, at Brooklyn Friends School. She is passionate about studying a wide range of literature and learning joyfully with students in the classroom. Having attended many workshops, including the Bard Institute for Writing and Thinking and Exeter’s Harkness for Humanities, that focus on the importance of mindful connection to, and expression of self, Liz values using journaling and writing as a way to explore mindfulness and creativity. Having also worked one on one with students for many years, Liz loves supporting and guiding students as they develop and take ownership over their own study, executive functioning, and academic skills and strategies. In addition to being a voracious reader, she is a huge fan of theater and museums, as well as spending as much time as possible exploring and playing outdoors. She received her BA from Bryn Mawr College and JD from Fordham University School of Law."
          />
          <ImageText
            src={Takehiro}
            name="Takehiro Shimizu"
            text="(mindfulness and somatics coach), M,AmSAT teaches the Alexander Technique in the US and Japan. He has taught somatic and mindfulness practices for many years to learners with diverse needs. He participated as an assistant teacher for musicians at Mannes School of Music at the New School, and for EDS (Ehlers-Danlos syndrome) and Hypermobility at Balance Arts Center. Takehiro holds a bachelor's degree in Human Science from Konan University in Kobe, Japan. He was trained as an Alexander Technique teacher through the American Center for the Alexander Technique and the Balance Arts Center. He also has been working as a jazz drummer for more than 20 years. He is an avid meditator and loves to read."
            />          
        </VStack>
      </Container>
      <Box h={100} />
      <ContactButton />
    </ChakraProvider>
  );
};

export default Team;
