import { Grid, GridItem, VStack, Center } from '@chakra-ui/react';
import React from 'react';
// import Header from "./components/header";

const Team = props => {
  // Hook to toggle dark

  return (
    <>
      <div
        className="headBox"
        style={{
          fontSize: '5vw',
        }}
      >
        <VStack
          w="100%"
          p={1}
          border="4px"
          borderColor="skyblue"
          // bg={'royalblue'}
          borderRadius={20}
        >
          <Grid
            margin="10px"
            // h="90%"
            templateRows="repeat(5, 1fr)"
            templateColumns="repeat(1, 1fr)"
            gap={4}
            borderRadius={20}
          >
            <GridItem
              colSpan={3}
              border="2px"
              borderColor="royalblue"
              p="3"
              borderRadius={20}
            >
              <Center h="100%" w="100%" fontSize="5vw" textAlign="center">
                {' '}
                <b>executive functions </b>
              </Center>
            </GridItem>
            <GridItem
              colSpan={3}
              border="2px"
              borderColor="royalblue"
              p="3"
              borderRadius={20}
            >
              <Center h="100%" w="100%" fontSize="5vw" textAlign="center">
                {' '}
                organization{' '}
              </Center>
            </GridItem>
            <GridItem
              colSpan={3}
              border="2px"
              borderColor="royalblue"
              p="3"
              borderRadius={20}
            >
              <Center h="100%" w="100%" fontSize="5vw" textAlign="center">
                {' '}
                creating systems{' '}
              </Center>
            </GridItem>
            <GridItem
              colSpan={3}
              border="2px"
              borderColor="royalblue"
              p="3"
              borderRadius={20}
            >
              <Center h="100%" w="100%" fontSize="5vw" textAlign="center">
                {' '}
                planning
              </Center>
            </GridItem>
            <GridItem
              colSpan={3}
              border="2px"
              borderColor="royalblue"
              p="3"
              borderRadius={20}
            >
              <Center h="100%" w="100%" fontSize="5vw" textAlign="center">
                {' '}
                parent support
              </Center>
            </GridItem>
          </Grid>
        </VStack>
        <br />

        <VStack
          w="100%"
          p={1}
          border="4px"
          borderColor="skyblue"
          borderRadius={20}
        >
          <Grid
            margin="10px"
            // h="90%"
            templateRows="repeat(4, 1fr)"
            templateColumns="repeat(1, 1fr)"
            gap={4}
            borderRadius={20}
          >
            <GridItem
              colSpan={3}
              border="2px"
              borderColor="magenta"
              p="3"
              borderRadius={20}
            >
              <Center h="100%" w="100%" fontSize="5vw" textAlign="center">
                {' '}
                <b>mindfulness and somatics </b>
              </Center>
            </GridItem>
            <GridItem
              colSpan={3}
              border="2px"
              borderColor="magenta"
              p="3"
              borderRadius={20}
            >
              <Center h="100%" w="100%" fontSize="5vw" textAlign="center">
                {' '}
                focusing{' '}
              </Center>
            </GridItem>
            <GridItem
              colSpan={3}
              border="2px"
              borderColor="magenta"
              p="3"
              borderRadius={20}
            >
              <Center h="100%" w="100%" fontSize="5vw" textAlign="center">
                {' '}
                body awareness{' '}
              </Center>
            </GridItem>
            <GridItem
              colSpan={3}
              border="2px"
              borderColor="magenta"
              p="3"
              borderRadius={20}
            >
              <Center h="100%" w="100%" fontSize="5vw" textAlign="center">
                {' '}
                emotions
              </Center>
            </GridItem>
          </Grid>
        </VStack>
      </div>
    </>
  );
};

export default Team;
