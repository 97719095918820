import React from "react";

// import Rotate from "../components/Rotate";
import {
  Box,
  Text,
  Heading,
  VStack,
  Link,
  Flex,
  Spacer,
  Container,
  Center
} from "@chakra-ui/react";

// import images
import So_mi_header from "../images/Ef/executivefunction_elements.png";
import Spring from "../components/HoverSpring";
import MagImage from "../components/MagnifyImage";
import useViewport from "../components/useViewport";
import ConceptBox from "../components/ConceptImageBoxEF"

const Ef = (props) => {
  // Hook to toggle dark

  const { width } = useViewport();
  const breakpoint = 620;

  return (
    <div className="concept">
      <Center>
        {" "}
        <Heading as="h1" size="2xl" fontWeight='normal'>
          {" "}
          executive functions coaching
        </Heading>
      </Center>
      <br />
      <br />
      
      {width < breakpoint ?  <Center> <ConceptBox />  <br/> <br/> </Center> : <> <VStack><Center><MagImage src={So_mi_header} bg_color='white'/> </Center> <br/> <Flex>
        <Box p="4"></Box>
        <Box p="4"></Box>
        <Box p="4"></Box>
        <Box p="4"></Box>
        <Box p="4"></Box>
        <Box p="4"></Box>
        <Box p="4"></Box>
        {/* <Spacer /> */}
        <Box p="4">
          {" "}
          <Spring color={"pink"} />{" "}
        </Box>
        <Box p="4"></Box>
        <Spacer />
        <Box p="4">
          <Spring color={"yellow"} />
        </Box>
      </Flex>
      </VStack> </> }
        <br/>
        <br/>
      {/* </Center> */}
      < br />
      <Box h='5px' p={5}></Box>
      <br/>
      <Container  maxW="container.md">
          <Heading as='h2' size='lg'>
            What do we offer?
          </Heading>

        </Container>
        <Container maxW='container.sm' >
        <Text fontSize="2xl">
          <ul>
            <li>
              one-on-one executive functions coaching with students of all ages
            </li>
            <li>ADHD coaching</li>
            <li>partnering with parents, schools, other specialists</li>
            <li>mindfulness skills training</li>
          </ul>
        </Text>
      </Container>
      <Flex>
        <Box p="4">
          {" "}
          <Spring color={"cyan"} />{" "}
        </Box>
        <Spacer />
        <Box p="4"></Box>
      </Flex>
      <Container maxW="container.md">
        <Heading as="h2" size="lg">
          {" "}
          What do our students learn?{" "}
        </Heading>
        </Container>
        <Container maxW="container.sm">
        <Text fontSize="2xl">
          <ul>
            <li>organize their work</li>
            <li>time management</li>
            <li>navigate distractions</li>
            <li>study skills</li>
            <li>set priorities and goals</li>
            <li>communicate effectively and mediate conflict</li>
            <li>self advocacy</li>
            <li>mindfulness and somatic skills</li>
          </ul>
        </Text>
      </Container>
      <br />
      <Flex>
        <Box p="4"></Box>
        <Box p="4">
          {" "}
          <Spring color={"#ABF535"} />{" "}
        </Box>
        <Box p="4">
          {" "}
          <Spring color={"#0000ff"} />{" "}
        </Box>
        <Box p="4"></Box>
        <Box p="4">
          {" "}
          <Spring color={"#CB35F5"} />{" "}
        </Box>
        <Spacer />
        <Box p="4"></Box>
      </Flex>
<br />
      <Text></Text>
      <Container maxW="container.md">
        <Heading as="h2" size="lg">
          How do we coach executive functions skills?
        </Heading>
        <Text fontSize="2xl">
          We tailor our process to each unique student. Our aim is to make
          learning these skills relevant, joyful and holistic. We do this by
          working with students on their areas of challenge. This might include
          academic work, morning routines or a job. We model better systems with
          them and identify the structures that work well. We progress step by
          step, building up these skills. Alongside tackling their challenges,
          we introduce the student to mindfulness and somatic practices.
        </Text>
        <br />
        <Text fontSize="2xl">
          At the heart of our teaching is our <Link color='blue' href="https://www.thebodyliterate.com/our_team" >incredible team</Link>. Our coaches have
          advanced degrees and years of experience. They are kind and
          passionate.
          
        </Text>
        </Container>
        <br />
        <Flex>
          <Box p="4"></Box>
          <Box p="4"></Box>
          <Spacer />
          <Box p="4">
            {" "}
            <Spring color={"#35F57F"} />{" "}
          </Box>

          <Box p="4"></Box>
        </Flex>
        <br/>

        <Container maxW='container.md' >
        <Text fontSize="2xl">
          <b>Executive functions</b> refer to processes that bring thinking into
          action. We help students plan tasks, organize information, manage time
          and communicate their needs. We specialize in executive functioning
          coaching.
        </Text>
        <br />
        <Text fontSize="2xl">
          We work with <b>neurodiverse</b> populations. Some have ADHD or
          sensory processing disorder. We also work with students with
          dyscalculia, processing/memory difficulties, dyslexia and more. We
          celebrate differences and we augment our work to meet every student's
          access needs.
        </Text>{" "}
        <br />
        <Text fontSize="2xl">
          We <b>partner with parents, schools and other specialists</b> by
          helping families build better systems at home. This might include
          homework incentive systems, time management structures, and goal
          setting. We often work with families in conflict with the education
          system. We help them navigate the best foot forward.
        </Text>{" "}
        <br />
        <Text fontSize="2xl">
          We help learners find <b>engagement</b> in their learning. Engagement
          refers to a student’s level of interest and agency in their
          learning. We help students find their interests and engage with their
          learning environment. Students learn to advocate for themselves. They
          apply their unique interests into their assignments. They learn to
          self-motivate as they feel embodied in their learning.
        </Text>{" "}
      </Container>
      <br />
      <Flex>
        <Spacer />
        <Box p="4"></Box>
        <Box p="4">
          <Spring color="#8ACFF9" />
        </Box>
        <Box p="4">
          <Spring color="#F056E3" />
        </Box>
        <Spacer />
        <Box p="4">
          <Spring color="#fff600" />
        </Box>
      </Flex>
    </div>
  );
};

export default Ef;
