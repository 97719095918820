import React from "react";
import ReactPlayer from "react-player";
import { Box } from '@chakra-ui/react';

function RP(props) {

  const url =
    // "github.com/cre8ture/audioFilesForBL/raw/main/1.mp3"
    "https://github.com/cre8ture/audioFilesForBL/blob/main/" +
    props.currPage +
    ".mp3?raw=true";
    console.log("the url is", url, "the page num is", props.currPage)


  return (
    <Box display="inline-block" w="246px">
      <ReactPlayer
        url={url}
        // "https://file-examples-com.github.io/uploads/2017/11/file_example_MP3_700KB.mp3"
        width="245px"
        height="50px"
        // style={{"textAlign": "left", "color": "red", "backgroundColor": "green"}}
        // light
        // playIcon = {<Button border={0} borderRadius={20} p={8}><Play/>  Listen to this Page </Button>}
        playing={false}
        controls={true}
      />
    </Box>
  );
}

export default RP;
