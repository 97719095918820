import React from "react";
import ChakraUIRenderer from 'chakra-ui-markdown-renderer';
import ReactMarkdown from 'react-markdown'

import { Container,
         Text
                } from '@chakra-ui/react';
function Header(props) {

//   const markdown = ` # Alexander Technique and Scoliosis in kids and teenagers 
//   _methodologies for managing scoliosis _
//   <br/>
//   ## What is scoliosis?`
  

  // need to use an if statement to CHANGE the classname of Drawer to top men and then add a top menu, depending on size
  return (
    <>
      <article>
        <Container maxW='container.md'>
          <Text fontSize='2xl'>
      <ReactMarkdown components={ChakraUIRenderer()} children={props.text} skipHtml />
      </Text>
        </Container >
      </article>
    </>
  );
}

export default Header;
