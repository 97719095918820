import React from "react"

import {
 Box,
 Text,
 Divider
} from '@chakra-ui/react'


function Testimonial(props) {


  return (
    <>
    <Divider/>
    <Box 
            w="55%"
            border="0"
            // borderColor="skyblue"
             
            borderRadius={20}

        >
            <Text 
              fontSize='1xl'
             >
              🌻&nbsp;{props.text}
              <br/> &nbsp; &nbsp;
              -&nbsp;{props.name}
              </Text>
          </Box>
    </>
  )
}

export default Testimonial