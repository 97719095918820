import React from 'react';
import Header from '../components/header';
import {
  ChakraProvider,
  theme,
  VStack,
  Center,
  Heading,
} from '@chakra-ui/react';

import ContactButton from '../components/ContactUs';
import TestimonialBox from '../components/TestimonialBox';

function TestimonialPage() {
  return (
    <ChakraProvider theme={theme}>
      <Header />

      <VStack>
        <Center>
          {' '}
          <Heading as="h1">What families are saying</Heading>
        </Center>
        <TestimonialBox
          text="That said, I cannot stress how helpful the sessions have been so far. Starting from the first meeting with you Kai, and subsequently, with Austin, it has been incredible to see Thijs come out of his sessions pumped and optimistic. I think he has a lot more to work on, for sure, but I am truly grateful for your work on getting him on the right track. He has thought about all of the stuff you two have covered, and it'll take time for him to remember all of it each time he feels anxious or derails, but at least he know understands how to try and fix his behavior. 
          "
          name="Mom to 16 year old, 2022"
        />
        <TestimonialBox
          text="As the new year begins, we wanted to express our deepest gratitude to you for all you have done for Alexander.  We can already see a difference in him and his attitude toward his work and toward people.  Please accept our thanks and we are looking forward to working with you in 2022.
          "
          name="Mom to 21 year old, 2021"
        />
        <TestimonialBox
          text="There are few things that make us happier than seeing our kids reach their potential. J is doing and feeling so much better and we wanted to take a minute to THANK YOU for all your hard work with him.  Please let me know if you did not receive it or if I should re-send it somewhere else. PS - Hope you're feeling better!

          Hope you both have a great holiday and New Year!"
          name="Dad to 17 year old, 2021"
        />

        <TestimonialBox
          text="Thank you! 
          I cannot tell you how much Luke enjoys his sessions with you and I agree that he is really taking away a lot from them. "
          name="Mom to 9 year old, 2021"
        />

        <TestimonialBox
          text="​Thanks so much for all your work with Dylan! I’ll never forget how he transformed in the first couple of sessions with you — he came out excited, happy, confident and loving himself and his surroundings. We’ll certainly keep in touch"
          name="Mom to 8 year old, 2019"
        />

        <TestimonialBox
          text="Thank you so much for your support and thoughtful engagement with Arlo this year. I, too, think his work with you has been very helpful; both the concrete supports and strategies you’ve offered, but also as another supportive, caring adult in his life. I’m very grateful for that."
          name="Mom to 12 year old 2019"
        />

        <TestimonialBox
          text="​Excellent! I’m so thankful that you’ve been able to squeeze us in this year. It’s been helpful for C. Middle school is tough and we really appreciate you working with us to build a better human. ​Again, I am very grateful for all the ways you’ve supported A this year. I would definitely recommend you to anyone looking for executive functioning coaching! And, I will absolutely be in touch."
          name="Mom to 7th grader, 2019"
        />

        <TestimonialBox
          text="​Thanks so much. She really likes the sessions with you! I think they are helping a lot.​Thanks for everything I am so pleased it’s all going well."
          name="Mom to 4th grader, 2019"
        />

        <TestimonialBox
          text="​We had her school conferences yesterday and all of her teachers remarked on how your work together is making a HUGE impact! We think so too! Thank you!!!!"
          name="Mom to 12 year old, 2019"
        />

        <TestimonialBox
          text="Your name came up today and I just wanted to let you know Mason is doing great. He got accepted to Tulane and Villanova for University. He is on the community board of downtown and did an internship at the Manhattan DA. I can't thank you enough for the work you did with Mason when he was struggling!"
          name="Mom to 18 year old, 2019"
        />

        <TestimonialBox
          text="I think that this breakthrough was huge. She seemed focused on desire to do the work but not clear why she navigates the way she does. Hopefully when we start with her therapies things will come to the surface. Keep doing what you are doing. I think today was hard but blurry good for her and I'm glad she has the clarity to articulate her feelings. Thank you."
          name="Mom to 17 year old, 2019"
        />

        <TestimonialBox
          text="​Thank you so much for these updates.  I can't begin to tell you how helpful they are and the tools you provide for G she will have for a lifetime.  I expect that she will spend a good part of this weekend studying and preparing for her upcoming midterms."
          name="Mom to 16 year old, 2019"
        />

        <TestimonialBox
          text="​Kai, seriously, you are the absolute best.  You pull O's voice out of him like no one else.  Thank you for this!"
          name="Mom to 8 year old, 2016"
        />

        <TestimonialBox
          text="Thank you so much for everything you’ve done with Seth—you’ve been incredibly helpful and supportive, and we are very grateful. You have been essential to Seth’s growth and academic achievements. If you ever need a reference for your executive function coaching, please don’t hesitate to contact us."
          name="Parent to HS graduate [worked with bodyLITERATE since 8th grade], 2018"
        />
        <TestimonialBox
          text="Hi Kai! Thanks for everything this semester. E has really improved her writing and her attitude about writing is so much more open and enthusiastic. Thank you for all you have done with her. You really made a difference and I feel like she really connected with you. I know she will miss seeing you so I will keep in touch about getting her occasional sessions when possible. Happy 2018!  "
          name="Mom to 4th grader, 2018"
        />

        <TestimonialBox
          text="He’s in good spirits and is really looking froward to seeing you. You have some magic that really makes him shine."
          name="Mom to 6th grader, 2018"
        />

        <TestimonialBox
          text="WOW! You accomplished so much with him today! He was in tears and thinking such negative thoughts about himself last night when trying to get started with this narrative. But here, I’m just seeing greatness in this piece and there’s so much truth to it that I can’t help but smile as I read it. Thank you so much for being able to squeeze in the time with him today. I know he’ll go to sleep much less stressed out and I really want more of those kinds of days and evenings for him. …..  Have a pleasant evening!"
          name="Mom to 8th grader on writing support, 2017"
        />

        <TestimonialBox
          text="Nice work! M is really improving and loves writing. No angst anymore!!! Thank you.
          "
          name="Mom to 4th grader, 2017"
        />

        <TestimonialBox
          text="Terrific – thanks Kai! I noted to D’s dad just this morning that he came out of the appointment smiling, more confident and even walking more smoothly. He had breathing techniques he was excited to use, absorbed some of the Stevie Wonder project information and is looking forward to the next time. I really couldn’t ask for more! 🙂"
          name="Mom to 3rd grader, 2017"
        />

        <TestimonialBox
          text="Thank you, Kai. I’ve passed these ideas on to the teachers. They all sound promising.
          I loved watching you work with W: a really good brew of enthusiasm, gentleness, activity, movement, concrete examples.
          "
          name="Mom to 3rd grader, 2017"
        />

        <TestimonialBox
          text="Great!!! Thanks again Kai,
          Don’t know how this year would have gone down without your support and guidance… She seems to be more on top of her work. I thank you for your time and attention. It all seems to be working out for her… I am eternally grateful for your gifts. This has been a nice partnership, which shall continue. Have a great day! I have also forwarded your contact info to a few friends.
          Have a great summer!
          "
          name="Mom to 10th grader at Murrow, 2017"
        />

        <TestimonialBox
          text="I’m happy to report that R is doing very well: he’s turned the corner academically and has been pulling straight As all year, including in his advanced Regents science class! Thanks again for all your work with him — I think it was really helpful."
          name="Mom to 7th grade Brooklyn student"
        />

        <TestimonialBox
          text="Thanks Kai! The teachers were really fantastic this year… Thanks so much for all your help this year. We are deeply grateful, and you made a huge contribution to O having such a fantastic year. It was really his best by far, both academically and socially. We are so proud of him! We will be in touch in August. Have a great summer!
          "
          name="Mom to 6th grader, 2017"
        />

        <TestimonialBox
          text="I am writing now because I wanted to let you know how happy we have been with the help you have given J. J has done such productive work with you and I think he will take away some of the work habits you have taught him."
          name="Mom to 8th grader, 2017"
        />

        <TestimonialBox
          text="Hi Kai, Something special happened today at your session with J…I think that this breakthrough was huge. She seemed focused on desire to do the work but not clear why she navigates the way she does. Keep doing what you are doing. I think today was… good for her and I’m glad she has the clarity to articulate her feelings. Thank you."
          name="Mom to high schooler, 2016"
        />

        <TestimonialBox
          text="P feels like she learned a lot from you today. She commented that she wants to do well and is feeling more confident after meeting with you. We are so thankful for your help.  And earlier in week she asked to make sure that you guys were scheduled to meet today. Very encouraging.”"
          name="Mom and dad to 6th grader"
        />

        <TestimonialBox
          text="Hi Kai, Thank you so much for your email and kind words. It has been a long year, and we couldn’t have done it without your continuous support. You are an amazing coach, so knowledgeable and so very kind. It has been a pleasure working with you. We will all miss you very much and will stay in touch. Thank you again."
          name="Mom and dad to 9 year old"
        />

        <TestimonialBox
          text="Thanks Kai…. Thanks for the detailed breakdown of this assignment. Having you available to help her push through is hard but very comforting to her as she can clearly know know that she can find her way out of the labyrinth. Confidence in understanding that is the key. You are amazing."
          name="Mom to 15 year old"
        />

        <TestimonialBox
          text="Of course I’ll be staying in touch, how could I not? I wanted to thank you for the help these past years and for the fair wishes. China is great so far."
          name="11th grader taking a Semester in China"
        />

        <TestimonialBox
          text="Hello Kai, I am texting u to let you know I’ll be going to study at [] university. I will be leaving next week Wednesday to move into the school. I have enjoyed working with you for many years and to help me and teach me on my journey to this moment. I’m happy to say I’m ready to take the college step and that it has been a pleasure to have you as my tutor these years. It’s been a difficult journey as well for me but You and me have seen my changes to getting more focused and organized and I’m happy to say I feel confident in my school work as well. Thank you for the wonderful years Kai. Hopefully my story of picking myself up will be the same motivation to other kids with the same academic struggles. Thank you Kai for the amazing tutoring that has helped me to transition to my new college experience."
          name="College bound 18 year old, Summer 2016"
        />

        <TestimonialBox
          text="Kai — thank you for everything. B has loved working with you + would love to continue in the fall…Thanks"
          name="Mom to 14 year old"
        />

        <TestimonialBox
          text="By the way, I finally reached out to the Aikido studio you recommended. I’m waiting to hear if they have space… Thank you so much for all your support, and for being so good at what you do. I’ll be in touch again at a later date."
          name="Mom to 9 year old"
        />

        <TestimonialBox
          text="Kai. I really loved your sessions…I’m going to miss you so much. The math we did was great, the Alexander Technique was awesome. And the tests we did were duper fun!…I want to do more!"
          name="6th grade student, Summer 2016"
        />

        <TestimonialBox
          text="Thank you Kai for all of your nurturing support of this school year. All in all it has been a much better year… L has more tools in his belt and that is wonderful! Thank you also for always sending updates and for your patience with my many concerning emails."
          name="Mother to 10th grader at Beacon, Summer 2016"
        />

        <TestimonialBox
          text="Kai, thanks very much for the notes below — and for all your good work with R over the year! It’s absolutely made a difference in helping him to be more mindful around his schoolwork. We appreciate all your help"
          name="C, mom to 7th grader at New Voices. Summer, 2016"
        />

        <TestimonialBox
          text="Hi Kai, Adam really enjoys his sessions with you; he is always in a good mood and proud of the work he’s done. He seems to be a little more engaged in school, too, and less resistant to schoolwork in general. Thanks again for the wonderful work that you do. "
          name="Mother to 7 year old"
        />

        <TestimonialBox
          text="Hello Kai, Thank you so much for conducting your informative and engaging workshop. I really enjoyed the workshop and the exercises were very helpful after a long day at work. I also received a lot compliments from parents and they really liked your workshop as well. Take care and enjoy the rest of your evening! With much gratitude, C"
          name="Head of PTA PS 11 after an executive functions and embodied mindfulness workshop workshop"
        />

        <TestimonialBox
          text="I am pretty sure Darwin won’t find an executive functions coach like you around here. I am so grateful that we found you and Darwin was able to spend time with you for all this time.
          "
          name="Mother of 4th grade student from Switzerland"
        />
        <TestimonialBox
          text="Dear Kai,
          Thank you for all the weekly plan updates. They help me a lot to stay on track and keep my grades up
          Thank you,
          "
          name="7th Grader at New Voices"
        />
        <TestimonialBox
          text="We want you to know that we’ve seen a real change in Robert’s attitude toward self-management since you guys have been meeting. He’s become much more able to manage himself to do his work"
          name="Mother of student at at New Visions"
        />

        <TestimonialBox
          text="Hello Kai, Last night for homework A. wrote the first section (introduction) of his latest personal narrative, which he outlined with you on Tuesday. I was impressed that he had a positive attitude about the task and he did a really nice job. He even asked me to help him stay on task!  I appreciate that he is approaching his schoolwork with more willingness and confidence. I also wanted to mention that he is always in a great mood when we are on our way to see you, and really happy afterwards, too, which is so wonderful to see. He really enjoys your sessions! I hope that he is becoming more open to creative movement and the Alexander technique…). Thank you so much for your amazing work."
          name="Mother to 7 year old, Brooklyn, December, 2015"
        />

        <TestimonialBox
          text="Hi Kai… I want to thank you for all the work you have been doing with Jack, the teachers as well as myself are seeing a drastic improvement in his effort and grades!!! Thanks again! Forever grateful"
          name="Mother to 13 year old, Brooklyn, December, 2015"
        />

        <TestimonialBox
          text="I thank you so much for seeing him and really understanding my questions and giving such thoughtful response. I think you really inspire T with your excitement about your work, your cool space and your great one on one style. Kids in big public schools don’t get a lot of that."
          name="Mother of 13 year old in Park Slope, 2015"
        />

        <TestimonialBox
          text="Thank you so much, Kai. I’m sure Eva benefited from all that you worked on. I appreciated your addressing my concerns about her run-on sentences and introductory hooks. And thank you again for all that you have done for Eva. She is developing more and more confidence in her writing and the focus and clarity of her writing are improving because of it. It means so much to Jack and me, and certainly to Eva, to know that she has your support, guidance, and encouragement."
          name="Mother of 11 year old, Park Slope, Brooklyn. 2015"
        />

        <TestimonialBox
          text="Dear Kai, …Again, I want to thank you for helping Daniel and for being such a great role figure. Daniel’s spirit always seems lifted when I pick him up. This is worth the world to me."
          name="Mother of 8 year old, Park Slope, Brooklyn. 2014"
        />

        <TestimonialBox
          text="Kai,Your work with James sounds fantastic. Thank you for supporting him so well, and so thoroughly. Please let me know if there’s anything I should know to help reinforce what you’re doing! Again, it sounds spot on! Thank you"
          name="Teacher at PS 321 Park Slope, Brooklyn. 2014
          "
        />

        <TestimonialBox
          text="ear Kai…Adam has a great time at his classroom publishing party today!  He was really proud of his work and got a lot of nice feedback from his classmates 🙂 Hopefully, he can bring it to share with you soon."
          name="Mother of 7.5 year old on his writing"
        />

        <TestimonialBox
          text="Kai,
          There is no doubt in my mind that you have been instrumental in getting him to this place. Thank you very much. You are hilarious and clearly know my son since you are making superhero references.  I would love it if he could harness some of that energy.  I think it would really take him to the next level.
          "
          name="Thank you for your lovely words"
        />

        <TestimonialBox
          text="“Thanks so much Kai–after Ollie got in the car yesterday he said, “That was really fun.” I’m always happy for you to incorporate movement, dance, Alexander technique stuff when you’re working with him. I keep asking him to take some sort of dance outside of school, because I know he’d enjoy it, but he refuses. He did ballet when he was 5 and loved it and he’s always enjoyed any kind of movement work they do at school. African Dance has replaced Streb (named after Elizabeth Streb), which they did at school this winter. Ollie loved it and I was even thinking of enrolling him in a Streb class in Williamsburg this summer.	Thanks again! "
          name="-Mother of 3rd grader, Ollie: Ollie’s lessons incorporate executive function coaching and embodiment through the Alexander Technique.  2014"
        />

        <TestimonialBox
          text="“Dear Kai,
          Thank you so much for the update! …I’ve also been getting very good reports on how he has been doing in school — so thank you for everything!
          All best wishes.”
          "
          name="Mom to 4th grade student working on executive functioning at PS 321, Park Slope, Brooklyn. 2014"
        />

        <TestimonialBox
          text="Hi Kai,
          I agree, it was great to meet you on Saturday…I saw the connections that you are wanting to make with him: systems, body, planner, web of who Billy sees himself to be. I think you really have something valuable to share and teach. I liked your very upfront approach. He is taking your ideas on how to study for the history assessment to heart. Tonight he will write out the rough answers in note form.
          "
          name="Mother of 14 year old at Berkeley Carroll working on executive functions and Alexander Technique. 2014."
        />

        <TestimonialBox
          text="“Kai worked magic with my daughter.  His combination of body work and academic support made all the difference f–or her during a time of academic and emotional struggle.  A peace would descend on her when she worked with him; she was able to tap into all her gifts and know her strengths through his work."
          name="Mother of 8th grader at Packer Collegiate Institute: we worked on both embodiment practices and on executive function skills to develop the student’s confidence and ability to adapt to her changing academic environment.  2013
          "
        />

        <TestimonialBox
          text="For two years now, Kai Kleinbard, an executive functioning skills and academic tutor and AmSAT Certified Alexander Technique professional, has been working with our son, who is in high school at Brooklyn Tech. Kai provides organizational support, skill teaching, patience, and real care and concern for our kid. It is a wonderful relationship, and we believe Kai is responsible in large part for our son being able to excel in school. Our son is very bright, but has terrible organization skills, cannot plan his assignments especially long term research assignments effectively, has little discipline re: time management, and thinks way too fast. Half the time he’s at the end with the answer before he’s begun. Kai also uses Alexander Technique (education process, posture, and body work that eliminates muscle tension to free up the mind and body to function more effectively) with our son to help get the boy focused and ready to work.review"
          name="Mother of HS student at Brooklyn Tech. From the Park Slope Parents’ (PSP) tween/teen recommendation site: http://www.parkslopeparents.com/6234_Kai-Kleinbard.html"
        />

        <TestimonialBox
          text="A. feels that he has control over his work and I couldn’t agree more,  his grades truly reflect it. Thank you for all that you have done , its been a great help. Again, thank you, I know A. appreciated your help."
          name="Mother and father of 8th grader at New Visions school in Brooklyn.  The student is an avid visual artist and into drama.  We work within all three lenses, academic, embodiment and creative to develop his foundation for executive functions. 2014"
        />

        <TestimonialBox
          text="hey Kai, the class was awesome. thank you for all the generosity and intensity you brought. each day I left feeling totally riveted and revitalized. it was amazing to have a space to explore this deep movement stuff so fearlessly and joyfully."
          name="Musician and composer/artist"
        />

        <TestimonialBox
          text="Dear Cori and Kai, by pure chance I stumbled over your wonderful website and I just wanted to write to you how exciting and inspiring it was to me to have a look at it (there is still a lot I haven’t read through…). I am a Alexander-Technique teacher myself and mother of a 10-year old boy, living in Germany. Thank you for bringing so much conscious constructive creativity and love into the world. Wow!! Best wishes from Munich."
          name="Doctor from Germany and fellow Alexander Technique teacher"
        />

        <TestimonialBox
          text="I wanted to add that my daughter went to Kai, and I thought he was really amazing . . . so, if you’re thinking of trying the Alexander Technique with him, I highly recommend him.
          "
          name="K. Purce writer and mom in Brooklyn"
        />

        <TestimonialBox
          text="Thank you so much for your amazing embodied mindfulness workshops. The students loved working with you and benefited greatly. With kind thoughts,
          "
          name="Middle School head. Learning Specialist at the Packer Collegiate Institute"
        />

        <TestimonialBox
          text="Hello Kai, Thanks again for the useful information and guidance you provided to me and my son Otis. Otis told me that he learned a lot and that people missed out by not coming to your workshop.
          "
          name="PTA president of PS 11, Winter, 2016"
        />

        <TestimonialBox
          text="Hi Kai,
          I really enjoyed this first class today… And thought I can regain body conscious through Tai Chi and the Alexander Technique and heal my shoulder injury. It really felt like I was bringing together knowledge from dancing and theater in the past into organic movement that builds up creating consciousness and meditation.
          Thank you!
          "
          name="Ellen, adult, studying the Alexander Technique to relieve chronic shoulder pain, 2015"
        />

        <TestimonialBox
          text="Kai, I’ve been meaning to tell you that C loved the session and intends to contact you when she returns from Disney.  So you will see her again, soon. She does think AT [Alexander Technique] will help her posture. Hopefully, we’ll get to see you again as well.  So happy Casey got to meet you. And I really want to see you dance!!"
          name="Mother of musical theatre college major.  Her daughter worked with me over the Summer on the Alexander Technique to improve her physical awareness, posture and mastery of dance.  2014"
        />
      </VStack>
      <ContactButton />
    </ChakraProvider>
  );
}

export default TestimonialPage;
