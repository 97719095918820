

  import React from 'react';
  import { ChakraProvider, theme } from '@chakra-ui/react';
  import Header from '../../components/header';
  import Markdown from '../../components/markDownBox'
  import ContactButton from "../../components/ContactUs"
  // import './a.css'

  const ATS = props => {
    // Hook to toggle dark
  const articleM = `
  # Alexander Technique and Mindfulness
My work as an Alexander Technique teacher has influenced my decade-plus of practicing mindfulness.  There are many corollaries; my AT practice informs my mindfulness practice by honing my ability to be aware of my body and be physically comfortable, while my mindfulness practice allows me to hone my awareness of the thoughts in my mind.
### The Alexander Technique
The Alexander Technique (AT) teaches me how to study myself.  It teaches me how to take information that my body gives me, whether through feeling, or from what I visually observe, and offers me a framework to identify how to become more efficient and concise with my movements. Through my study of the AT, I am more comfortable in my body — I can sit without discomfort, and when I do feel physical strain from misalignment, I can subtly shift myself to re-center.  Thus, the Alexander Technique offers both a process for how to physically develop mastery and immediate principles on alignment.
### Mindfulness Practice
[Jon Kabat-Zinn, a leader in the American mindfulness effort, gives a brief [definition of mindfulness in this video linked **here**.](https://www.youtube.com/watch?v=wPNEmxWSNxg)


For me, mindfulness is the practice of awareness, being able to be present to what is happening in the moment, the here-and-now.  This is practiced through meditation, whether it’s traditional Zen sitting (where one counts the breath), listening to music deeply (and hearing each layer of sound), visualizations, such as self hypnosis.  The mind is a muscle, the more I meditate, the greater my ability to direct my focus and avoid distracting thoughts.
### Mindfulness and the Alexander Technique
Both mindfulness practices and the Alexander Technique are about being conscious of the present moment.  The Alexander Technique has principles to guide an individual to a more ideal place of being, pertaining to joint alignment. In the AT there is a unification between thinking and body orientation. For example, running more efficiently by imagining the whole body moving forward and up. By thinking of the body moving directly through space, there is a shift in physical alignment, which translates into greater comfort and movement efficiency.
![Three runners from the 10k Athens 2004 Olympics. Hicham El Gouraj leads the pack. Notice the forward and up direction of Hicham El Gouraj (runner farthest to the right).  Notice how his body alignment is different from the other two runners.](https://media.gettyimages.com/photos/hicham-el-guerrouj-of-morocco-competes-in-the-mens-1500-metre-final-picture-id51214228?s=612x612)

_Image description: Three runners from the 10k Athens 2004 Olympics. Hicham El Gouraj leads the pack. Notice the forward and up direction of Hicham El Gouraj (runner farthest to the right). Notice how his body alignment is different from the other two runners._

Mindfulness is simply being aware of the present moment. Meditation, a practice of mindfulness, traditionally, will have practitioners become aware of their present feelings, whether it’s pain, happiness, etc. Consistent through all these emotions, is the constant flow of the breath.  By refocusing one’s attention on the breath, one no longer gets pulled into ruminations, distracting thoughts that are not physically real.  In contrast, the Alexander Technique has specific principles on how one might think. It asks one to be aware of what’s happening and then to “re-direct” one’s thinking through the lens of the Alexander Technique principles.(some of these lenses are linked [**here**](https://s77i5.skipdns.link/embody/alexander-technique-2/).
### Tensegrity and Mindfulness
![Tensegrity: the characteristic property of a stable three-dimensional structure consisting of members under tension that are contiguous and members under compression that are not.
  ](https://i.ytimg.com/vi/ZfhEMabjaVk/hqdefault.jpg)
  _Image description: A three dimensional structure, built with rods. Each rod is pulled apart with equal force from other rods. This creates an 3 dimensional shape. The shape is stable, exhibiting tensegrity._



Tensegrity: the characteristic property of a stable three-dimensional structure consisting of members under tension that are contiguous and members under compression that are not.
![More images of tensegrity in action. Notice how the components equally pull each other away from a center. This creates stability. 
  
  ![Four images of various examples of tensegrity in action](https://www.researchgate.net/profile/Dimitrije-Stamenovic/publication/261322830/figure/fig1/AS:296929129975815@1447804829191/Physical-tensegrity-models-a-A-3-strut-tensegrity-model-composed-of-wood-sticks-and_Q320.jpg)
_Image description: Four images of various examples of tensegrity in action_



More images of tensegrity in action. Notice how the components equally pull each other away from a center. This creates stability. 
Stable human movement is filled with tensegrity – the right amount of tension creates an ease and dynamic flow of movement

The spine is a great example of tensegrity

![The spine is a great example of tensegrity](https://i.pinimg.com/originals/71/38/ed/7138ede17a7d41f5346a34e18b448df3.jpg)
_image descirption: three images in columns. The center image looks like a spine. It is a model. The model has strings pulling equally on all sides to great a spine-like structure._


Similarly, a suspension bridge supports itself through a tensegral structure. There is tension between the top and bottom of the bridge.
![Similarly, a suspension bridge. There is tension between the top and bottom of the bridge.
](https://images.pexels.com/photos/3212335/pexels-photo-3212335.jpeg?cs=srgb&dl=suspension-bridge-3212335.jpg&fm=jpg)
_Image description: The red Golden Gate Bridge in California. A misty day. One can see how the bridge suspends itself using tensegrity._


A model of a stretching in all directions at once, creates clarity of where the center of gravity is. In Chinese martial arts, the center of gravity is considered an inch below the belly button.
![Humans stretching in all directions at once, creates clarity of where the center of gravity is. In Chinese martial arts, the center of gravity is considered an inch below the belly button.](http://intensiondesigns.ca/files/papers/geometry_of_anatomy/fig_1_tensegrity_skeleton.jpg)
_Image description: This is a wired structure of a human in an 'X' like shape._


DNA creates a double helix structure of tensegrity, stabilizing the sequence of bases
![DNA creates a double helix structure of tensegrity, stabilizing the sequence of bases](https://shop.sciencefirst.com/20760-thickbox_default/model-dna.jpg)
_Image description: A model of the DNA double helix, spirals around itself._

The meditator has an evenness of radiance around her. There is just the right amount of tension (known as tensegrity, to allow the body to relax into relaxation and stability)

![The meditator has an evenness of radiance around her. There is just the right amount of tension (known as tensegrity, to allow the body to relax into relaxation and stability)](https://i.pinimg.com/originals/e9/19/d0/e919d00dc2838cd802a6300f5df7cf36.jpg)
_Image description: A golden statue of the Bhudda in meditation. The head-top is pointed to the sky. The base of the Bhudda (their pelvis) is dropping, weighted into the ground._

All these images demonstrate the idea of “tensegrity,” an evenness of tension throughout the entire structure.  Much like a suspension bridge, the opposition of all joints allows the structure to be stable.  In the statue of the meditator, tensegrity allows the body to be calm.  Notice the evenness of support through the ground, out the arms, up through the head.  It’s as if a circle surrounds the circle, creating both peace and stability.   Notice the consistent physical alignment in each picture — head over spine, body firmly rooted in the  ground.  In my own experience as a meditator, it’s extraordinarily challenging to meditate when the body is uncomfortable.  Before becoming an Alexander Technique teacher, I was an avid Zen meditator, studying at the Village Zendo, (their website is linked **[here](https://villagezendo.org/) in New York City, sometimes meditating for upwards of two hours at a time. When my body was in pain, [ie, I was slumping, and straining my back] I found it very difficult to calm myself down. Through the Alexander Technique, I learned how to drop my sitz-bones into my zafu [meditation pillow], directing my spine long. Now my capacity to meditate has increased.  Because I no longer have discomfort, I am able to meditate and calm my mind even more.


While I believe there is a certain power in being able to sit with physical discomfort and still focus the mind, I have learned that reducing the amount of distractions (such as physical pain), allows me to focus more on developing calmness of mind and my capacity to focus.  This has translated into being about to concentrate/focus more when there are distractors in situations beyond meditation.  In effect, having a peaceful practice of meditation has helped me create that type of mindstate even when I’m not meditating.



Through my practice of AT,  I have found that my ability to accurately sense my body has resulted in a sharper mind.  The clarity/precision of my focus often correlates to what is happening in the mind and is expressed in the body.  This is why in Tai Chi (a martial art and moving meditation practice), teachers ask students to “find the stillness in the movement, and the movement in the stillness.”  The stillness in the movement, is the calm mind, focused on one task and the movement in the stillness, is the activity of the breath and calmness of mind. In this way, the mind and body are inseparable.


Lastly, The AT also hones your senses ([**here’s](https://s77i5.skipdns.link/brooklyn-nyc-sensory-processing-disorder-alexander-technique-teenagers-adolescents/)  an article about that)..  Your ability to be present with the body is increased. For example, I can sense my breathing better, because I am more practiced in understanding my alignment. Even the Buddha agrees with this, having said, “To keep the body in good health is a duty… otherwise we shall not be able to keep our mind strong and clear. [Buddha].  The body supports the mind and vice-versa.  This interchange is paramount to the gestalt of mindfulness.  When I am more aware of where discomfort emanates–I have a more accurate sense of my physical presence.  


Mindfulness is the awareness of the present moment, and this awareness is supported by a daily practice of meditation.  Meditation, the practice of mindfulness, benefits from a well aligned and tuned body.


____
By Kai Kleinbard,
director of bodyLITERATE 
2016

`


    return (
      <>
      <ChakraProvider theme={theme}>
        <Header />
        <Markdown text={articleM} />
        <ContactButton />
      </ChakraProvider>
      </>
    );
  };
  
  export default ATS;
  







